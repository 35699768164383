import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getPersonalInformation,
  getPersonalId,
  obtenerPersona,
  getCompanyInformation,
  getCompanyId,
  openModal,
  obtenerEmpresa
} from "../../../../actions/QuoteActions";
import {
  polizasEmpresa
} from "../../../../services/QuotationService";
import {
  checkRut,
  validarRutEspeciales,
  required,
  isValidEmail,
  validarNumeroDotacion,
  soloNumeros,
  validarTextoVacio
} from "../../../../utils/Functions";
import {
  COTIZADOR_PASO_INFORMACION_PERSONAL_CARGO,
  NOMBRE,
  APELLIDOS,
  CORREO,
  RUT,
  TELEFONO,
  RAZON_SOCIAL,
  RUT_EMPREZA,
  NUMERO_DOTACION,
  ALERTA_ERROR_NUMERO_DOTACION,
  NUMERO_VACIO,
  VERDADERO,
  FALSO,
  POSICION_CERO,
  POSICION_UNO,
  EXPRESION_REGULAR_RUT,
  TEXTO_VACIO,
  SIGNO_GUION,
  SIGNO_PUNTO,
  TIPO_STRING,
  RANGO_MINIMO,
  POSICION_INICIAL_RUT,
  MENOS_UNO,
  POSICION_FINAL_RUT,
  EXP_CARACTERES_ESPECIALES,
  STRING_VACIO,
  EXP_ESPACIOS,
  ESPACIO_VACIO,
  EXP_SALTO_LINEAS,
  EXP_SOLO_NUMERO,
  EXP_CARACTERES_ESPECIALES_AMPERSAND,
} from "../../../../utils/Constants";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TagManager from "react-gtm-module";
import Select from "react-select";
//prueba

class InformacionPersonal extends Component {
  constructor(props) {
    super(props);
    this.objDesplazamiento = React.createRef();
    this.procesarPaso = this.procesarBtnContinuar.bind(this);
    this.state = {
      informacionPersonal: {
        idPersona: null,
        nombre: null,
        apellidos: null,
        rut: "",
        cargo: null,
        email: null,
        telefono: ""
      },
      informacionEmpresa: {
        idEmpresa: null,
        razonSocial: "",
        rutEmpresa: "",
        numeroDotacion: null,
        idRegion: this.props.store.reducer.patrocinador.seleccionarRegion ? Number(process.env.REACT_APP_ID_REGION_DEFAULT) : NUMERO_VACIO,
        idEjecutivo: -1,
        idRepresentanteClasificacion: 0
      },
      blnNombre:VERDADERO,
      blnApellido:VERDADERO, 
      blnRut: VERDADERO,
      blnCorreo: VERDADERO, 
      blnTelefono: VERDADERO,
      blnRazonSocial: VERDADERO,
      blnRutEmpresa: VERDADERO,
      blnNumeroDotacion: FALSO
    };
    TagManager.dataLayer({
      dataLayer: {
        event: "traza",
        event_category: "Cotizacion",
        event_label: "Cotización paso 1 - Datos Personales",
      },
    });
  }

  componentDidMount() {
    let { informacionPersonal, informacionEmpresa } = this.props.store.reducer;
   
    if (informacionPersonal !== null) {

      let strRut  = "";

      if (informacionPersonal.rut !== null && informacionPersonal.rut !== undefined &&
          informacionPersonal.digitoVerificador !== null && informacionPersonal.digitoVerificador !== undefined) {
          strRut  = `${informacionEmpresa.rutEmpresa}-${informacionEmpresa.digitoVerificador}`;
      }

      informacionPersonal = { ...informacionPersonal, rut: strRut };
      
      this.setState({ informacionPersonal });
    }

    if (informacionEmpresa !== null) {

      let blnNumeroDotacion = validarNumeroDotacion(informacionEmpresa.numeroDotacion);
      this.setState({ blnNumeroDotacion });

      let strRutEmpresa  = "";

      if (informacionEmpresa.rutEmpresa !== null && informacionEmpresa.rutEmpresa !== undefined &&
        informacionEmpresa.digitoVerificador !== null && informacionEmpresa.digitoVerificador !== undefined) {
        strRutEmpresa  = `${informacionEmpresa.rutEmpresa}-${informacionEmpresa.digitoVerificador}`;
      }

      informacionEmpresa = { ...informacionEmpresa, rutEmpresa: strRutEmpresa };
      
      this.setState({ informacionEmpresa });
    }
    this.props.mostrarCargando(FALSO);
    if (this.objDesplazamiento.current) this.objDesplazamiento.current.scrollIntoView();
  }

  validarValorPorCampo = e => {
    const objPatrocinador = this.props.store.reducer.patrocinador;
    let lstEjecutivos     = this.props.store.reducer.ejecutivos;
    let {informacionPersonal, informacionEmpresa} = this.state;
    
    if (e.target && e.target.name) {
      switch (e.target.name) {
        case NOMBRE:
          let blnNombre = validarTextoVacio(e.target.value);
          this.setState({blnNombre});
          
          const validacionNombre =  e.target.value
              .replace(EXP_CARACTERES_ESPECIALES,STRING_VACIO)
              .replace(EXP_ESPACIOS,ESPACIO_VACIO)
              .replace(EXP_SALTO_LINEAS,STRING_VACIO)
              .replace(EXP_SOLO_NUMERO,STRING_VACIO)
           
          
          informacionPersonal = {
            ...informacionPersonal,
           nombre:validacionNombre
          };
          break;

        case APELLIDOS:
          let blnApellido = validarTextoVacio(e.target.value);
          this.setState({blnApellido});


          const validacionApellido=  e.target.value
              .replace(EXP_CARACTERES_ESPECIALES,STRING_VACIO)
              .replace(EXP_ESPACIOS,ESPACIO_VACIO)
              .replace(EXP_SALTO_LINEAS,STRING_VACIO)
              .replace(EXP_SOLO_NUMERO,STRING_VACIO)

          informacionPersonal = {
            ...informacionPersonal,
            apellidos:validacionApellido
          };
          break;

        case CORREO:
          let blnCorreo = isValidEmail(e.target.value);
          this.setState({ blnCorreo });
          informacionPersonal = {
            ...informacionPersonal,
            [e.target.name]: e.target.value
          };
          break;

        case RUT:
          let strRut = this.validarRut(e.target.value)
          let blnRut = (checkRut(strRut) && validarRutEspeciales(strRut))?VERDADERO:FALSO;
          this.setState({ blnRut });
          informacionPersonal = {
            ...informacionPersonal,
            [e.target.name]: strRut.replace(/\./g,"").toUpperCase()
          };
          break;
      
        case TELEFONO:
          let blnTelefono = e.target.value.length === Number(process.env.REACT_APP_VALOR_TELEFONO_VALIDO)? VERDADERO: FALSO;
          this.setState({ blnTelefono });
          informacionPersonal = {
            ...informacionPersonal,
            [e.target.name]: e.target.value
          };
          break;

        case RAZON_SOCIAL:
          let blnRazonSocial = validarTextoVacio(e.target.value);
          this.setState({ blnRazonSocial });
          const validacionRazonSocial=  e.target.value
              .replace(EXP_CARACTERES_ESPECIALES_AMPERSAND,STRING_VACIO)
              .replace(EXP_ESPACIOS,ESPACIO_VACIO)
              .replace(EXP_SALTO_LINEAS,STRING_VACIO)
              

            

          informacionEmpresa = {
            ...informacionEmpresa,
            razonSocial : validacionRazonSocial
          };
          break;

        case RUT_EMPREZA:
          let strRutEmpresa = this.validarRut(e.target.value);
          let blnRutEmpresa = (checkRut(strRutEmpresa) && validarRutEspeciales(strRutEmpresa))?VERDADERO:FALSO;
          this.setState({ blnRutEmpresa });
          informacionEmpresa = {
            ...informacionEmpresa,
            [e.target.name]: strRutEmpresa.replace(/\./g,"").toUpperCase()
          };
          break;

        case NUMERO_DOTACION:
          let blnNumeroDotacion = validarNumeroDotacion(e.target.value, objPatrocinador.tieneMaximoPoblacion);
          this.setState({ blnNumeroDotacion });
          informacionEmpresa = {
            ...informacionEmpresa,
            [e.target.name]: e.target.value
          };
          break;

        default:
          informacionEmpresa = {
            ...informacionEmpresa,
            [e.target.name]: e.target.value
          };
          informacionPersonal = {
            ...informacionPersonal,
            [e.target.name]: e.target.value
          };
          break;
      }
      required([e.target]);
    }
    else{
      let objEjecutivo = lstEjecutivos.find(objEjecutivo => objEjecutivo.idRepresentante === e.value);
      informacionEmpresa = {
        ...informacionEmpresa,
        idEjecutivo: objEjecutivo.idRepresentante,
        idRepresentanteClasificacion: objEjecutivo.clasificacion.idRepresentanteClasificacion
      };
    }
    this.setState({ informacionPersonal, informacionEmpresa });
  };

  convertirRut (strRut) {
    return typeof strRut === TIPO_STRING
      ? strRut.replace(EXPRESION_REGULAR_RUT, TEXTO_VACIO).toUpperCase()
      : TEXTO_VACIO
  }

  validarRut (strRut) {
    strRut = this.convertirRut(strRut)
  
    var strResultado = strRut.slice(-POSICION_INICIAL_RUT, MENOS_UNO) + SIGNO_GUION + strRut.substr(strRut.length - RANGO_MINIMO)
    for (var intPosicion = POSICION_INICIAL_RUT; intPosicion < strRut.length; intPosicion += POSICION_FINAL_RUT) {
      strResultado = strRut.slice(-POSICION_FINAL_RUT - intPosicion, - intPosicion) + SIGNO_PUNTO + strResultado
    }
  
    return strResultado
  }

  procesarBtnRetroceder(objEvento) {
    objEvento.preventDefault();
    let {informacionPersonal, informacionEmpresa} = this.state;

    this.props.getPersonalInformation(informacionPersonal);
    this.props.getCompanyInformation(informacionEmpresa);
    this.props.prevPage();
  }

  async procesarBtnContinuar(objEvento) {
    objEvento.preventDefault();
    this.props.mostrarCargando(VERDADERO);

    if (!this.state.blnNombre || !this.state.blnApellido || !this.state.blnRut || 
        !this.state.blnCorreo || !this.state.blnTelefono || !this.state.blnRazonSocial || 
        !this.state.blnRutEmpresa){
          this.props.mostrarCargando(FALSO);
          return;
        }
    
    let blnRequerido = required(document.querySelectorAll(".form-wzd input:required"));
    
    if (NUMERO_VACIO === blnRequerido) {

      let {informacionPersonal, informacionEmpresa} = this.state;
      const lstPolizas = await polizasEmpresa(informacionEmpresa.rutEmpresa);

      if (!this.state.blnNumeroDotacion || lstPolizas) {
        this.props.mostrarCargando(FALSO);
        this.props.openModal();
      } 
      else{
        await this.enviarInformacionPersonalPorPreCotizacion(informacionPersonal);
        await this.enviarInformacionEmpresaPorPreCotizacion(informacionEmpresa)
                
        this.props.nextPage();
      }
    }
    else{
      this.props.mostrarCargando(FALSO);
    }
  }

  async enviarInformacionPersonalPorPreCotizacion(){

    let { informacionPersonal } = this.state;
    let objRut                 = informacionPersonal.rut.split("-");
    let objApellido           = informacionPersonal.apellidos.split(" ");
    const objPersona           = {
      persona: {
        ...informacionPersonal,
        rut: Number(objRut[POSICION_CERO]),
        digitoVerificador: objRut[POSICION_UNO],
        apellidoPaterno: objApellido[POSICION_CERO],
        apellidoMaterno: objApellido[POSICION_UNO] === undefined || objApellido[POSICION_UNO] === null ? " " : objApellido[POSICION_UNO],
        cargo: COTIZADOR_PASO_INFORMACION_PERSONAL_CARGO
      }
    };

    this.setState({informacionPersonal: objPersona.persona})
    this.props.getPersonalInformation(objPersona.persona);
  }

  async enviarInformacionEmpresaPorPreCotizacion(){
    
    let { informacionEmpresa, informacionPersonal } = this.state;
    let objRutEmpresa = informacionEmpresa.rutEmpresa.split("-");
    const objEmpresa  = {
      empresa: {
        ...informacionEmpresa,
        idPersona: informacionPersonal.idPersona,
        rutEmpresaFull: informacionEmpresa.rutEmpresa,
        rutEmpresa: Number(objRutEmpresa[POSICION_CERO]),
        digitoVerificador: objRutEmpresa[POSICION_UNO]
      }
    };

    this.setState({informacionEmpresa: objEmpresa.empresa})
    this.props.getCompanyInformation(objEmpresa.empresa);
  }

  render() {
    const objPatrocinador = this.props.store.reducer.patrocinador;
    const lstRegion       = this.props.store.reducer.regiones;
    const blnRegion       = this.props.store.reducer.patrocinador.seleccionarRegion;
    const blnEjecutivo    = this.props.store.reducer.patrocinador.seleccionarEjecutivo;
    let lstEjecutivos     = [];
    let objEjecutivoSelec = null;

    this.props.store.reducer.ejecutivos.forEach(objEjecutivo => {

      let strNombreEjecutivo = objEjecutivo.nombre;
      strNombreEjecutivo = objEjecutivo.apellidoPaterno ? `${strNombreEjecutivo} ${objEjecutivo.apellidoPaterno}` :strNombreEjecutivo;
      strNombreEjecutivo = objEjecutivo.apellidoMaterno ? `${strNombreEjecutivo} ${objEjecutivo.apellidoMaterno}` :strNombreEjecutivo;
      let objOpcion      = {value: objEjecutivo.idRepresentante, label: strNombreEjecutivo };
      
      lstEjecutivos.push(objOpcion)
      if (this.state.informacionEmpresa.idEjecutivo === objEjecutivo.idRepresentante) {
        objEjecutivoSelec = objOpcion;
      }
    });

    return (
      <React.Fragment>
        <form ref={this.objDesplazamiento} className="form-wzd row animated">
          <fieldset id="personalInformation" className="pr30 pb20 col-xs-12 col-sm-12 col-md-6">
            <legend>Ingresa tu información personal</legend>

            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="form-group">
                  <label>Nombre</label>
                  <input
                    name="nombre"
                    value={this.state.informacionPersonal.nombre}
                    onChange={this.validarValorPorCampo}
                    type="text"
                    className="form-control"
                    placeholder=""
                    required
                    minLength={1}
                    maxLength={50}
                  />
                  {!this.state.blnNombre && (
                    <small className="form-text text-danger">
                      Campo Obligatorio.
                    </small>
                  )}
                </div>
              </div>

              <div className="col-xs-12 col-sm-6">
                <div className="form-group">
                  <label>Apellidos</label>
                  <input
                    name="apellidos"
                    value={this.state.informacionPersonal.apellidos}
                    onChange={this.validarValorPorCampo}
                    type="text"
                    className="form-control"
                    placeholder=""
                    required
                    minLength={1}
                    maxLength={50}
                  />
                  {!this.state.blnApellido && (
                    <small className="form-text text-danger">
                      Campo Obligatorio.
                    </small>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>RUT</label>
              <InputMask
                name="rut"
                value={this.state.informacionPersonal.rut}
                onChange={this.validarValorPorCampo}
                required
                className="form-control"
                placeholder="ej. 12345678-9"
                type="text"
                maskChar=""
              />
              {!this.state.blnRut && (
                <small className="form-text text-danger">
                  RUT Inválido.
                </small>
              )}
            </div>

            <div className="form-group">
              <label>Correo electrónico</label>
              <input
                defaultValue={this.state.informacionPersonal.email}
                name="email"
                onChange={this.validarValorPorCampo}
                type="email"
                required
                className="form-control"
                placeholder="ej.: tunombre@tucorreo.com"
              />
              {!this.state.blnCorreo && (
                <small className="form-text text-danger">
                  Formato de email incorrecto.
                </small>
              )}
            </div>

            <div className="form-group">
              <label>Teléfono</label>
              <InputMask
                name="telefono"
                value={this.state.informacionPersonal.telefono}
                onChange={this.validarValorPorCampo}
                required
                className="form-control"
                mask="+56\ \9 9999 9999"
                maskChar=""
              />
              {!this.state.blnTelefono && (
                <small className="form-text text-danger">
                  Teléfono incompleto.
                </small>
              )}
            </div>
          </fieldset>

          <hr/>

          <fieldset id="companyInformation" className="pl30 pb20 col-xs-12 col-sm-12 col-md-6">
            <legend>Ingresa la información de tu empresa</legend>

            <div className="form-group">
              <label>Razón Social</label>
              <input
                name="razonSocial"
                value={this.state.informacionEmpresa.razonSocial}
                onChange={this.validarValorPorCampo}
                type="text"
                className="form-control"
                placeholder="Nombre de la Empresa"
                required
                minLength={1}
                maxLength={100}
              />
              {!this.state.blnRazonSocial && (
                <small className="form-text text-danger">
                  Campo Obligatorio.
                </small>
              )}
            </div>

            <div className="form-group">
              <label>RUT</label>
              <InputMask
                name="rutEmpresa"
                value={this.state.informacionEmpresa.rutEmpresa}
                onChange={this.validarValorPorCampo}
                required
                className="form-control"
                placeholder="Introduzca el rut de la empresa"
                type="text"
                maskChar=""
              />
              {!this.state.blnRutEmpresa && (
                <small className="form-text text-danger">
                  RUT Inválido.
                </small>
              )}
            </div>

            <div className="form-group">
              <label>Número de personas que trabajan en su empresa</label>
              <input
                name="numeroDotacion"
                defaultValue={this.state.informacionEmpresa.numeroDotacion}
                onKeyPress={soloNumeros}
                onChange={this.validarValorPorCampo}
                type="number"
                maxLength="2"
                minLength="1"
                min={process.env.REACT_APP_CANTIDAD_MINIMA_TITULARES}
                max={objPatrocinador.tieneMaximoPoblacion ? process.env.REACT_APP_CANTIDAD_MAXIMA_TITULARES: ""}
                className="form-control"
                placeholder=""
                required
                data-tip={ALERTA_ERROR_NUMERO_DOTACION}
              />
            </div>

            { (blnRegion) && (
              <div className="form-group">
                <label>Región</label>
                <select
                  name="idRegion"
                  defaultValue={this.state.informacionEmpresa.idRegion}
                  onChange={this.validarValorPorCampo}
                  className="form-control"
                >
                  {lstRegion.map((objRegion, i) => (
                    <option key={`region_${i}`} value={objRegion.idRegion}>
                      {objRegion.glosaRegion}
                    </option>
                  ))}
                </select>
              </div>
            )}

            { (blnEjecutivo) && (
              <div className="form-group">
                <label>Ejecutivo de Mutual</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={this.validarValorPorCampo}
                  name="idEjecutivo"
                  value={objEjecutivoSelec}
                  options={lstEjecutivos}
                  placeholder="Selecciona un ejecutivo"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: `${objPatrocinador.color}`,
                      primary: `${objPatrocinador.color}`
                    }
                  })}
                />
              </div>
            )}
          </fieldset>

          <footer className="wizard-buttons text-center">
            <button type="submit" className="btn btn-primary" onClick={(objEvento) => {this.procesarBtnContinuar(objEvento)}}>
              Continuar
              <FontAwesomeIcon icon="arrow-right" />
            </button>
            <button type="button" className="btn btn-atras" onClick={(objEvento) => {this.procesarBtnRetroceder(objEvento)}}>
              <FontAwesomeIcon icon="arrow-left" />
            </button>
          </footer>
        </form>
      </React.Fragment>
    );
  }
}

const convertirEstadoApropiedad = state => ({
  store: state
});

export default connect(convertirEstadoApropiedad, {
  getPersonalInformation,
  getPersonalId,
  obtenerPersona,
  getCompanyInformation,
  getCompanyId,
  openModal,
  obtenerEmpresa
})(InformacionPersonal);
