import React from 'react'
import { Paso } from './Paso'

export const PasoContenedor = ({pasosCotizador, intPagina}) => {
  return (
    <>
      <ul className="wizard-step">
        {pasosCotizador.map((objPaso, intIndice) => (
          <Paso key={intIndice} objPaso={objPaso} intIndice={intIndice} intPagina={intPagina} />
        ))}
      </ul>
    </>
  )
}
