import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { buscarPatrocinadorPorCodigoSitio } from "./services/PatrocinadorService"
import { FALSO, PARAMETRO_PLANES, PATROCINADORES_CONFIGURACION, PATROCINADOR_DEFECTO, VERDADERO } from "./utils/Constants"

const strPathname  = window.location.pathname.substring(1).split("/");

const SelectorTema = ({ objPatrocinador, children }) => {

  let Tema = React.lazy(() => import(`${objPatrocinador.tema}`))
  return (
    <>
      <React.Suspense fallback={<></>}>
         <Tema />
      </React.Suspense>
      {children}
    </>
  )
}

buscarPatrocinadorPorCodigoSitio(strPathname[1])
.then(
  res => {
    const blnCotizaPatrocinador = res.objeto !== undefined;
    const idPatrocinador        = blnCotizaPatrocinador ? res.objeto.idPatrocinador : PATROCINADOR_DEFECTO;
    const objConfiguracion      = PATROCINADORES_CONFIGURACION.find( (elemento) => elemento.id === idPatrocinador);
    let objBuscarParametro      = new URLSearchParams(window.location.search);
    let strParametroPlan        = objBuscarParametro.get(PARAMETRO_PLANES);

    if (strParametroPlan) {
      objConfiguracion.planes.forEach((objPlan)=>{ 
        objPlan.mostrar = (objPlan.plan === strParametroPlan) ? VERDADERO : FALSO;
      })
    }

    let objPlan = objConfiguracion.planes.find((plan)=> plan.mostrar );
    let objPatrocinador = {
      ...objConfiguracion,
      ...objPlan
    }

    document.title = objPatrocinador.nombreAplicacion;
    document.getElementById("favicon").href = require(`./app/assets/images/${objPatrocinador.favicon}`);
    ReactDOM.render(<SelectorTema objPatrocinador={objPatrocinador}><App objPatrocinador={objPatrocinador}/></SelectorTema>, document.getElementById('root'));
  }

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
