export const GET_PERSONAL_INFORMATION = "GET_PERSONAL_INFORMATION";
export const GET_COMPANY_INFORMATION = "GET_COMPANY_INFORMATION";
export const GET_QUOTE = "GET_QUOTE";
export const GET_PLAN_INFO = "GET_PLAN_INFO";
export const GET_TRAMO_ASEGURADO = "GET_TRAMO_ASEGURADO";
export const GET_PRECOTIZACION = "GET_PRECOTIZACION";
export const GET_REGIONES = "GET_REGIONES";
export const NEXT_PAGE = "NEXT_PAGE";
export const PREV_PAGE = "PREV_PAGE";
export const RESET = "RESET";
export const OPEN_MODAL = "OPEN_MODAL";
export const GET_PLAN_SELECTED = "GET_PLAN_SELECTED";
export const GET_ID_PRECOTIZACION = "GET_ID_PRECOTIZACION";
export const GET_SALUD_DIGITAL = "GET_SALUD_DIGITAL";
export const GET_PERSONAL_ID = "GET_PERSONAL_ID";
export const GET_COMPANY_ID = "GET_COMPANY_ID";
export const OBTENER_PERSONA = "OBTENER_PERSONA";
export const OBTENER_EMPRESA = "OBTENER_EMPRESA";
export const OBTENER_PRECOTIZACION = "OBTENER_PRECOTIZACION";
export const OBTENER_TRABAJADORES = "OBTENER_TRABAJADORES";
export const OBTENER_PATROCINADOR = "OBTENER_PATROCINADOR";
export const OBTENER_DISTRIBUCION_TRAMOS = "OBTENER_DISTRIBUCION_TRAMOS";
export const OBTENER_POBLACION = "OBTENER_POBLACION";
export const OBTENER_TARJETAS = "OBTENER_TARJETAS";
export const OBTENER_COBERTURAS = "OBTENER_COBERTURAS";
export const OBTENER_COMBINACION_PLANES = "OBTENER_COMBINACION_PLANES";
export const OBTENER_UF = "OBTENER_UF";
export const OBTENER_HISTORIAL_COTIZACIONES = "OBTENER_HISTORIAL_COTIZACIONES";
export const OBTENER_EJECUTIVO = "OBTENER_EJECUTIVO";