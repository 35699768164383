import React from 'react'
import Modal from '../../../utils/modal'

export const ModalContacto = ({ objPatrocinador, modal }) => {
  return (
    <Modal image={require("../../assets/images/modal-contacto.jpg")} open={modal} >
      <h3 className="text-center">¿Te asesoramos?</h3>
      <p>{`Este producto está pensado para empresas ${objPatrocinador.modalColaboradoresCantidad} colaboradores, sin historia en seguros complementarios de salud. Te invitamos a
        conocer nuestros productos `}
        <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_PRODUCTOS_URL}>
          aquí
        </a>
      </p>
      <p>Si quieres que un asesor se contacte contigo, por favor haz click en el siguiente botón </p>
      <a target="_blank"
        rel="noopener noreferrer"
        href={process.env.REACT_APP_CONTACTAME_URL}
        className="btn btn-modal mt-5" >
        Contáctame
      </a>
    </Modal>
  )
}
