export function typeWriter(txt, speed, element, i) {
  if (i < txt.length) {
    element.innerHTML += txt.charAt(i);
    i++;
    setTimeout(() => {
      typeWriter(txt, speed, element, i++);
    }, speed);
  }
}

export function formatRut(value) {
  let temp_rut = "";
  value = value.replace(/-/g, "");
  if (value.length > 1) {
    for (let i = 0; i < value.length; i++) {
      const element = value[i];
      if (i === value.length - 1) {
        temp_rut = temp_rut + "-" + element;
      } else {
        temp_rut += element;
      }
    }

    value = temp_rut;
  }
  return value;
}

export function checkRut(rut) {
  // Despejar Puntos
  let valor = rut
    .replace(".", "")
    .replace(".", "")
    .replace("-", "");

  // Aislar Cuerpo y Dígito Verificador
  let cuerpo = valor.slice(0, -1);
  let dv = valor.slice(-1).toUpperCase();

  // Formatear RUN
  rut = cuerpo.concat("-").concat(dv);

  // Si no cumple con el mínimo ej. (n.nnn.nnn)
  if (cuerpo.length < 7) {
    return false;
  }

  // Verifica el digito verificador
  if (dv.toString() !== GetDigVer(cuerpo).toString()) {
    return false;
  } else {
    return true;
  }
}

function GetDigVer(cuerpoRut) {
  let suma = 0,
    dig = 0,
    digver = 0,
    multiplo = 2,
    largo = cuerpoRut.length;

  while (largo !== 0) {
    dig = cuerpoRut.substr(largo - 1, 1);
    largo = largo - 1;
    suma = suma + dig * multiplo;
    multiplo = multiplo + 1;
    if (multiplo > 7) {
      multiplo = 2;
    }
  }

  let resto = suma - Math.floor(suma / 11) * 11;
  let fin = 11 - resto;

  if (fin === 10) {
    digver = "K";
  } else {
    if (fin === 11) {
      digver = 0;
    } else {
      digver = fin;
    }
  }

  return digver;
}

export function decodeFormParams(params) {
  var pairs = params.substring(1).split("&"),
    result = {};

  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("="),
      key = decodeURIComponent(pair[0]),
      value = decodeURIComponent(pair[1]),
      isArray = /\[\]$/.test(key),
      dictMatch = key.match(/^(.+)\[([^\]]+)\]$/);

    if (dictMatch) {
      key = dictMatch[1];
      var subkey = dictMatch[2];

      result[key] = result[key] || {};
      result[key][subkey] = value;
    } else if (isArray) {
      key = key.substring(0, key.length - 2);
      result[key] = result[key] || [];
      result[key].push(value);
    } else {
      result[key] = value;
    }
  }

  return result;
}

export function soloNumeros(e) {
  var key = window.event ? e.which : e.keyCode;
  if (key < 48 || key > 57) {
    e.preventDefault();
  }
}

export function validarRutEspeciales(rut) {
  switch (rut) {
    case "11111111-1":
    case "1111111-1":
    case "22222222-2":
    case "2222222-2":
    case "33333333-3":
    case "3333333-3":
    case "44444444-4":
    case "4444444-4":
    case "55555555-5":
    case "5555555-5":
    case "66666666-6":
    case "6666666-6":
    case "77777777-7":
    case "7777777-7":
    case "88888888-8":
    case "8888888-8":
    case "99999999-9":
    case "9999999-9":
    case "00000000-0":
    case "0000000-0":
      return false;
    default:
      return true;
  }
}

export function required(array) {
  let requerid = 0;
  for (let i = 0; i < array.length; i++) {
    let element = array[i];
    if (element.value.trim().length === 0) {
      element.style.borderColor = "red";
      requerid++;
    } else {
      element.style.borderColor = "#ced4da";
    }
  }
  return requerid;
}

export function isValidEmail(mail) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(mail);
}
export function validarTexto(texto) {
  const EXPRESION_REGULAR = /^[a-zA-ZÀ-ÿ\u00f1\u00d1 +]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1 +]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1 +]+$/g;
  return EXPRESION_REGULAR.test(texto);

}

export function validarTextoVacio(texto) {
  return texto.length === 0 ? false : true
}
export function validarTextoYNumero(texto){
  const EXPRESION_REGULAR = /^[A-Za-z0-9áéíóúÁÉÍÓÚñÑ.& ]+$/g;
  return EXPRESION_REGULAR.test(texto);

}

export function validarNumeroDotacion(intNumeroDotacion, blnMaximo){

  if ((Number(intNumeroDotacion) >= Number(process.env.REACT_APP_CANTIDAD_MINIMA_TITULARES) &&
       Number(intNumeroDotacion) <= Number(process.env.REACT_APP_CANTIDAD_MAXIMA_TITULARES) && blnMaximo) ||
      (Number(intNumeroDotacion) >= Number(process.env.REACT_APP_CANTIDAD_MINIMA_TITULARES) && !blnMaximo)){
        return true
  }
    
  return false;
}