import React from 'react'
import { TEXTO_VACIO } from '../../../../../utils/Constants'

export const TarjetaCabecera = ({ objTarjeta, blnSimulador, precioMensaje }) => {

  return (
    <header className="tarjeta-cabecera">
      <h3 className="tarjeta-titulo">{objTarjeta.nombre}</h3>
      <p className="tarjeta-descripcion">{objTarjeta.descripcion}</p>
      <p className="tarjeta-tope">{objTarjeta.tope}</p>
      { !blnSimulador && (
        <>
        <section className="tarjeta-precio">
          <h1 className="tarjeta-precio--UF">{objTarjeta.primaBrutaMensual} UF</h1>
          <small className="tarjeta-precio--CLP">{objTarjeta.primaBrutaMensualCLP} *</small>
          <span className="tarjeta-precio--titulo">Prima mensual total</span>
          <span className="tarjeta-precio--subtilulo">IVA incluido</span>
        </section>
        { precioMensaje && precioMensaje !== TEXTO_VACIO && (
          <p className="tarjeta-precio-mensaje">{precioMensaje}</p>
        )}
        </>
      )}
    </header>
  )
}
