import ServicesFactory from "../config/servicesFactory";

let services = new ServicesFactory();

export async function getGatewayToken() {
  try {
    return await services
      .get(process.env.REACT_APP_SERVLET_URL, false)
      .then(res => {
        if (res !== undefined && res.status === 200 ) {
          services.setAuthorization(
            "Bearer " + res.data.map.AccessToken.myArrayList[0]
          );
          return res.data;
        } else {
          return [];
        }
      });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function crearSimularCalculoPrimaFija(obj) {
  try {
    return await services
      .post(`${process.env.REACT_APP_BUS_COTIZADOR_URL}/simularCalculoPrimaFija`, obj,false)
      .then(res => {
        if (undefined !== res && null !== res.status && 200 === res.status) {
          if (res.data.status.codigo === "001") {
            return res.data.objeto;
          } else {
            return [];
          }
        } else {
          return [];
        }
      });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function listarConPrimaFija(token) {
  try {
    return await services.get(`${process.env.REACT_APP_BUS_COTIZADOR_URL}/listarConPrimaFijaPorToken/${token}`,false).then(res => {
      if (undefined !== res && null !== res.status && 200 === res.status) {
        if (res.data.status.codigo === "001") {
          return res.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function listarTramoAsegurado() {
  try {
    return await services.get(`${process.env.REACT_APP_BUS_COTIZADOR_URL}/listarTramoAsegurable`,false).then(res => {
      if (undefined !== res && null !== res.status && 200 === res.status) {
        return res.data;
      } else {
        return [];
      }
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function crearCopiaCierreCalculo(objeto) {
  try {
    return await services
      .post(`${process.env.REACT_APP_BUS_COTIZADOR_URL}/precotizacion/cerrarCalculoPrecotizacion`, objeto,false)
      .then(res => {
        if (undefined !== res && null !== res.status && 200 === res.status) {
          if (res.data.status.codigo === "001") {
            return res.data.objeto;
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function listarRegiones() {
  try {
    return await services.get(`${process.env.REACT_APP_API_URL}/geografica/listarRegiones`, false).then(res => {
      if (undefined !== res && null !== res.status && 200 === res.status) {
        if (res.data.status.codigo === "001") {
          return res.data.objeto;
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function sendPrecotizacionInformation(obj) {
  try {
    return await services.post(`${process.env.REACT_APP_BUS_COTIZADOR_URL}/precotizacion/crear`, obj,false).then(res => {
      if (undefined !== res && null !== res.status && 200 === res.status) {
        if (res.data.status.codigo === "001") {
          return res.data.objeto;
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function actualizarPrecotizacion(obj) {
  try {
    return await services.post(`${process.env.REACT_APP_BUS_COTIZADOR_URL}/precotizacion/actualizar`, obj,false).then(res => {
      if (undefined !== res && null !== res.status && 200 === res.status) {
        if (res.data.status.codigo === "001") {
          return res.data.objeto;
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function polizasEmpresa(rutEmpresa) {
  try {
    return await services
      .get(`${process.env.REACT_APP_API_URL}/poliza/listarPorRutEmpresa/${rutEmpresa}`, false)
      .then(res => {
        if (undefined !== res && null !== res.status && 200 === res.status) {
          if (res.data.status.codigo === "001") {
            return validarPolizasVigentes(res.data.objeto);
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
  } catch (error) {
    console.error(error);
    return [];
  }
}

/* ************************************
 ************ VALIDAR DATA ************
 **************************************/
function validarPolizasVigentes(polizas) {
  let vigente = false;
  for (let p of polizas) {
    let fechaActual = new Date();
    let fechaHasta = new Date(p.vigenciaHastaPoliza);

    fechaHasta.setFullYear(fechaHasta.getFullYear() + 3);
    if (fechaActual < fechaHasta) return (vigente = true);
  }

  return vigente;
}

export async function buscarCotizacionAdherencia(cantidadTrabajadores, idSubproducto ) {
  
  try {
    return await services
      .get(`${process.env.REACT_APP_API_URL_BACKSUSCRIPCION}/adherencia/listarPorCantidadPoblacionYIdSubproducto/${cantidadTrabajadores}/${idSubproducto}`,false)
      .then(res => {
        if (undefined !== res && null !== res.status && 200 === res.status) {
          if (res.data.status.codigo === "001") {
            return res.data;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function buscarCoberturasPorIdPatrocinador(intIdPatrocinador) {

  try {
    return await services
      .get(`${process.env.REACT_APP_API_URL_COTIZADOR}/plantilla/listarPorIdPatrocinadorBase/${intIdPatrocinador}`,false)
      .then(objRespuesta => {
        if (undefined !== objRespuesta && null !== objRespuesta.status && 200 === objRespuesta.status) {
          if (objRespuesta.data.status.codigo === "001") {
            return objRespuesta.data;
          } else {
            return [];
          }
        } else {
          return [];
        }
      });
  } catch (objError) {
    return [];
  }
}

export async function buscarUfPorFecha(intIdMoneda,strFechaHoy) {
  
  try {
    return await services
      .get(`${process.env.REACT_APP_API_URL_CATALOGO}/contabilidad/valordiario/tipocambio/buscar/${intIdMoneda}/${strFechaHoy}`,false)
      .then(objRespuesta => {
        if (undefined !== objRespuesta && null !== objRespuesta.status && 200 === objRespuesta.status) {
          if (objRespuesta.data.status.codigo === "001") {
            return objRespuesta.data;
          } else {
            return [];
          }
        } else {
          return [];
        }
      });
  } catch (objError) {
    return [];
  }
}

export async function buscarCombinacionPlanesPorIdPatrocinador(intIdPatrocinador) {

  try {
    return await services
      .get(`${process.env.REACT_APP_API_URL_COTIZADOR}/plantilla/listarPorIdPatrocinador/${intIdPatrocinador}`,false)
      .then(objRespuesta => {
        if (undefined !== objRespuesta && null !== objRespuesta.status && 200 === objRespuesta.status) {
          if (objRespuesta.data.status.codigo === "001") {
            return objRespuesta.data;
          } else {
            return [];
          }
        } else {
          return [];
        }
      });
  } catch (objError) {
    return [];
  }
}

export async function buscarRepresentantesPorIdPatrocinadorIdClasificacion(intIdPatrocinador, intIdClasificacion) {

  try {
    return await services
    .get(`${process.env.REACT_APP_API_URL_BACKSUSCRIPCION}/representante/listarPorIdPatrocinadorIdClasificacion/${intIdPatrocinador}/${intIdClasificacion}`,false)
      .then(objRespuesta => {
        if (undefined !== objRespuesta && null !== objRespuesta.status && 200 === objRespuesta.status) {
          if (objRespuesta.data.status.codigo === "001") {
            return objRespuesta.data.objeto;
          } else {
            return [];
          }
        } else {
          return [];
        }
      });
      
  } catch (objError) {
    return [];
  }
}