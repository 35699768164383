import React from "react";
import { 
  ANCHO_MINIMO_PANTALLA, 
  ORDENAMIENTO_VALORES_IGUALES, 
  ORDENAMIENTO_VALOR_MAYOR, 
  ORDENAMIENTO_VALOR_MENOR, 
  POSICION_CERO 
} from "../../../../utils/Constants";
import { TarjetaCabecera } from "./Cabecera/Cabecera";
import { Cobertura } from "./Coberturas/Cobertura";
import { useEffect } from "react";

const abrirArchivo = (objArchivo) => {
    
  const blnArreglo = Array.isArray(objArchivo);

  if (blnArreglo) {
    const blnArchivosIgual = objArchivo.every( (val, indice, arr) => val === arr[POSICION_CERO] );

    blnArchivosIgual ? window.open(objArchivo.shift()) : objArchivo.forEach(enlace => window.open(enlace));
  }
  else{
    window.open(objArchivo);
  }
}

export const Tarjeta = (objProps) => {

  const objReferencia = React.useRef(null);

  let { blnSimulador, objTarjeta,  objPatrocinador} = objProps;
  let objCoberturaPrincipal = objTarjeta.coberturas ? objTarjeta.coberturas.find((objCobertura) => objCobertura.principal ): null;
  let lstCoberturaSegundarias = objTarjeta.coberturas ? objTarjeta.coberturas.filter(objCobertura => !objCobertura.principal) : [];
  lstCoberturaSegundarias.sort((objCobertura1, objCobertura2) => objCobertura1.id > objCobertura2.id ? ORDENAMIENTO_VALOR_MENOR : objCobertura1.id < objCobertura2.id ? ORDENAMIENTO_VALOR_MAYOR : ORDENAMIENTO_VALORES_IGUALES);

  useEffect(() => {
    if (!objReferencia.current) return; // wait for the elementRef to be available

    if (window.innerWidth >= ANCHO_MINIMO_PANTALLA) {
      let intElementoLargo = objReferencia.current.offsetHeight;
      let intTarjetaLargo  = objReferencia.current.parentElement.parentElement.offsetHeight;
      let intColumnaLargo  = objReferencia.current.parentElement.parentElement.parentElement.offsetHeight;
      let intLargoFaltante = Number(intColumnaLargo - intTarjetaLargo);
  
      objReferencia.current.style.minHeight = `${Number(intElementoLargo + intLargoFaltante)}px`;
    }
  }, [objReferencia]);

  return (
  <section className="tarjeta" >
    <TarjetaCabecera objTarjeta={objTarjeta} blnSimulador={blnSimulador} precioMensaje={objPatrocinador.precioMensaje}/>
    <article className="tarjeta-cuerpo">
      { 
        objCoberturaPrincipal && (
          <Cobertura 
            objCobertura={objCoberturaPrincipal} 
            blnSimulador={blnSimulador} 
            objTarjeta={objTarjeta} 
            coberturaId={"prin-0"}
            seleccionarCoberturaLista={objProps.seleccionarCoberturaLista}
            seleccionarCobertura={objProps.seleccionarCobertura}
            />
        )}

      <p className="tarjeta-cuerpo-parrafo">Escoge a continuación las coberturas opcionales que quieras agregar a tu seguro</p>
      <ul ref={objReferencia} className="tarjeta-cobertura-extra">
      { lstCoberturaSegundarias.map((objCobertura, intIndice) => {
          return(
            <li key={intIndice}>
            <Cobertura 
              objCobertura={objCobertura} 
              blnSimulador={blnSimulador} 
              objTarjeta={objTarjeta} 
              coberturaId={`seg-${objTarjeta.id}-${intIndice}`} 
              seleccionarCoberturaLista={objProps.seleccionarCoberturaLista}
              seleccionarCobertura={objProps.seleccionarCobertura}
            />
            </li>
          )
        })
      }
      </ul>

      <h4 className="tarjeta-detalle-titulo">Detalle de prima por trabajador</h4>
      <ul className="tarjeta-detalle">
        <li className="tarjeta-detalle-item">
          Titular sin cargas 
          <span>
            <span>{objTarjeta.primaTotalTitular} UF</span>
            <small>{objTarjeta.primaTotalTitularCLP} *</small>
          </span>
        </li>
        <li className="tarjeta-detalle-item">
          Titular + 1 carga 
          <span>
            <span>{objTarjeta.primaTotalCarga1} UF</span>
            <small>{objTarjeta.primaTotalCarga1CLP} *</small>
          </span>
        </li>
        <li className="tarjeta-detalle-item">
          Titular + 2 cargas 
          <span>
            <span>{objTarjeta.primaTotalCarga2} UF</span>
            <small>{objTarjeta.primaTotalCarga2CLP} *</small>
          </span>
        </li>
      </ul>
      { !blnSimulador && (
          <>
            <h2 className="tarjeta-detalle-total">
              Prima afecta total
              <span>
                <span>{objTarjeta.primaAfectaTotal} UF</span>
                <small>{objTarjeta.primaAfectaTotalCLP} *</small>
              </span>
            </h2>

            <button type="button" className="btn btn-solicitar" onClick={() => {objProps.solicitarPlan(objTarjeta.id)}}>
              Solicitar
            </button>
            <button type="button" className="btn btn-arvicho" onClick={() => {abrirArchivo(objTarjeta.urlArchivo)}}>
              <span>Ver Detalle del plan</span>
            </button>
          </>
        )
      }
    </article>
  </section>
  )
}