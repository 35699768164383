import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const Paso = ({objPaso, intIndice, intPagina}) => {
  return (
    <>                
      <li key={`${intIndice}`} className={ intPagina === objPaso.numero ? "wizard-step--doing" : intPagina < objPaso.numero ? "wizard-step--todo" : "wizard-step--done" }> 
        { (intPagina > objPaso.numero) ? (
          <span className="wizard-step-icon"><FontAwesomeIcon icon="check" /></span>
          ) : (
          <span className="wizard-step-number">{objPaso.numero}</span>
        )}
        <span className="wizard-step-name">{objPaso.nombre}</span>
      </li>
    </>
  )
}
