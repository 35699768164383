import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import {
  getCompanyInformation,
  guardarPoblacionPorCotizacion
} from "../../../../actions/QuoteActions";
import {
  soloNumeros,
  validarNumeroDotacion
} from "../../../../utils/Functions";
import Adherencia from "../DistribucionDotacion/Adherencia/Adherencia";
import DetalleCarga from "../DistribucionDotacion/DetalleCarga/DetalleCarga";
import {
  buscarCotizacionAdherencia
} from "../../../../services/QuotationService";
import {
  ID_SUDPRODUCTO, 
  VALOR_CIEN, 
  VALOR_CERO, 
  MENOS_UNO,
  ALERTA_ERROR_FALTA_ADHERIR_TRABAJADORES,
  ALERTA_CLASE,
  ALERTA_ERROR_CANTIDAD_FALTANTA_TRABAJADORES,
  SIGNO_NUMERAL,
  ALERTA_ERROR_TRABAJADORES_ADHERIDOS_NO_PERMITIDO,
  CLASE_FORM_CONTROL,
  CLASE_FORM_CONTROL_ERROR,
  FORMATO_CODIGO_ESPANOL,
  FORMATO_ESTILO_MONEDA,
  FORMATO_PESO_CHILENO,
  DECIMALES_UF,
  ALERTA_ERROR_NUMERO_DOTACION,
  NUMERO_VACIO,
  VERDADERO,
  FALSO,
  SECCION_POBLACION,
  COMPORTAMIENTO,
  ALERTA_ERROR_COBERTURAS_FALTANTES,
  MAX_FILA_TARJETA,
  TAMANO_FILA_TARJETA
} from "../../../../utils/Constants";
import Swal from 'sweetalert2';
import { Row, Col} from "react-bootstrap";
import { Tarjeta } from "../Tarjeta/Tarjeta";
import TagManager from "react-gtm-module";

class SeleccionCobertura extends Component {
  constructor(props) {
    super(props);
    this.objDesplazamiento = React.createRef();
    this.seccionPoblacion = React.createRef()
    this.solicitarPlan = this.solicitarPlan.bind(this);
    this.state = {
      informacionEmpresa: {
        numeroDotacion: null
      },
      numeroDotacionPrevio: 0,
      blnNumDotacionValido: true,
      blnNumDotacionCambio: false,
      blnMostrarDistribucionCarga: false,
      blnBtnCambiarDistribucionCarga: false,
      poblacion: {
        trabajadoresTotal: 0,
        porcentajeAdherencia:null,
        trabajadoresMinimo:null,
        trabajadoresFaltante: 0,
        trabajadoresAdherido: 0
      },
      tramosDistribucion: null
    };
    TagManager.dataLayer({
      dataLayer: {
        event: "traza",
        event_category: "Cotizacion",
        event_label: "Cotización paso 3 - Seleccion de Coberturas",
      },
    });  
  }

  componentDidMount() {
    let { informacionEmpresa, poblacion } = this.props.store.reducer;
   
    if (informacionEmpresa !== null) {

      let blnNumDotacionValido = validarNumeroDotacion(informacionEmpresa.numeroDotacion);
      this.setState({ informacionEmpresa, blnNumDotacionValido, numeroDotacionPrevio: informacionEmpresa.numeroDotacion });
    }

    if (poblacion !== null) {
      this.setState({ poblacion});
    }

    this.props.mostrarCargando(FALSO);
    if (this.objDesplazamiento.current) this.objDesplazamiento.current.scrollIntoView();
  }

  actualizarDistribucionCarga = (intTrabajadoresAdherido) => {
    
    let { poblacion } = this.state;
    let trabajadoresFaltante  = poblacion.trabajadoresTotal - intTrabajadoresAdherido;
    
    poblacion = {...poblacion, 
                  trabajadoresFaltante: trabajadoresFaltante,
                  trabajadoresAdherido : intTrabajadoresAdherido
                };
    
    if (Number(trabajadoresFaltante) === Number(NUMERO_VACIO)) {
      this.props.tarjetasCalcular();
    }
    
    this.props.guardarPoblacionPorCotizacion(poblacion);
    this.setState({poblacion})       
  }

  async validarValorPorCampo(objEvento){

    let { informacionEmpresa, poblacion }     = this.state;
    const intTrabajadoresTotalPrevio          = this.state.numeroDotacionPrevio;
    const intTrabajadoresTotal                = objEvento.target.value;
    let blnNumDotacionValido                  = validarNumeroDotacion(intTrabajadoresTotal);
    let blnNumDotacionCambio                  = (intTrabajadoresTotal !== intTrabajadoresTotalPrevio)? VERDADERO:FALSO;
    const { objeto : {porcentajeAdherencia} } = await buscarCotizacionAdherencia(intTrabajadoresTotal,ID_SUDPRODUCTO);
    let intTrabajadoresMinimo                 = Math.ceil( intTrabajadoresTotal * (porcentajeAdherencia / VALOR_CIEN))

    poblacion = {...poblacion, 
                  trabajadoresTotal: intTrabajadoresTotal,
                  porcentajeAdherencia : porcentajeAdherencia,
                  trabajadoresMinimo: intTrabajadoresMinimo
                };
    
    informacionEmpresa = { ...informacionEmpresa,
                            numeroDotacion: intTrabajadoresTotal
                          }
    
    let blnBtnCambiarDistribucionCarga = poblacion.trabajadoresAdherido !== poblacion.trabajadoresTotal ? VERDADERO:FALSO;
    this.props.getCompanyInformation(informacionEmpresa);
    this.props.guardarPoblacionPorCotizacion(poblacion);
    this.setState({ blnNumDotacionValido, blnNumDotacionCambio, poblacion },()=>{this.mostrarDistribucionPorTramo(blnBtnCambiarDistribucionCarga)});
  }

  mostrarDistribucionPorTramo( blnClickBtn ){

    let { blnNumDotacionValido, blnNumDotacionCambio } = this.state;

    let blnMostrarDistribucionCarga  =  (blnNumDotacionValido && blnNumDotacionCambio && !blnClickBtn) ||
                                        (blnNumDotacionValido && blnClickBtn) ? VERDADERO:FALSO;
    this.setState({ blnMostrarDistribucionCarga, blnBtnCambiarDistribucionCarga: blnClickBtn });
  }

  ocultarDistribucionPorTramo(){
    let { blnNumDotacionCambio, poblacion } = this.state;

    if (blnNumDotacionCambio) {
      if (Number(poblacion.trabajadoresTotal) !== Number(poblacion.trabajadoresAdherido)) {
        Swal.fire({
          title: ALERTA_ERROR_FALTA_ADHERIR_TRABAJADORES,
          customClass: {
            title: ALERTA_CLASE,
            confirmButton: ALERTA_CLASE,
            popup: ALERTA_CLASE
          }
        }).then(() => {
          return;
        })
      }
    }
    
    this.setState({ blnMostrarDistribucionCarga: FALSO, blnBtnCambiarDistribucionCarga: FALSO },()=>{return});
  }

  async solicitarPlan(intIdTarjeta){

    this.props.mostrarCargando(VERDADERO);

    let { poblacion }   = this.state
    let { lstTarjetas }  = this.props;
    const objTarjeta    = lstTarjetas.find((objTarjeta) => objTarjeta.id === intIdTarjeta);
    let blnCoberturasOK = VERDADERO;

    objTarjeta.coberturas.forEach(objGrupo => {
      if (objGrupo.blnRequerido && !objGrupo.blnSeleccionada) {
        blnCoberturasOK = FALSO;
      }
    });

    if (Number(poblacion.trabajadoresTotal) === Number(poblacion.trabajadoresAdherido) &&
        blnCoberturasOK) {

      await this.props.cerrarCotizacion(intIdTarjeta);
    }
    else{
      this.props.mostrarCargando(FALSO);

      objTarjeta.coberturas.forEach(objGrupo => {
        if (objGrupo.blnRequerido && !objGrupo.blnSeleccionada) {
          blnCoberturasOK = FALSO;
        }
      });

      if (!blnCoberturasOK) {
        let strCoberturasFaltante = "";
        objTarjeta.coberturas.forEach(objGrupo => {
          if (objGrupo.blnRequerido && !objGrupo.blnSeleccionada) {
            strCoberturasFaltante = strCoberturasFaltante+", "+objGrupo.cobertura.nombre
          }
        });
        Swal.fire({
          title: ALERTA_ERROR_COBERTURAS_FALTANTES+strCoberturasFaltante,
          customClass: {
            title: ALERTA_CLASE,
            confirmButton: ALERTA_CLASE,
            popup: ALERTA_CLASE
          }
        });
      }

      let intTrabajadoresFaltante = Number(poblacion.trabajadoresTotal - poblacion.trabajadoresAdherido);

      if (Number(poblacion.trabajadoresTotal) !== Number(poblacion.trabajadoresAdherido) &&
          intTrabajadoresFaltante >= VALOR_CERO){
          
        Swal.fire({
          title: ALERTA_ERROR_CANTIDAD_FALTANTA_TRABAJADORES.replace(SIGNO_NUMERAL,intTrabajadoresFaltante),
          customClass: {
            title: ALERTA_CLASE,
            confirmButton: ALERTA_CLASE,
            popup: ALERTA_CLASE
          }
        })

        document.getElementById(SECCION_POBLACION).scrollIntoView({ behavior: COMPORTAMIENTO });
      }

      if (Number(poblacion.trabajadoresTotal) !== Number(poblacion.trabajadoresAdherido) &&
          intTrabajadoresFaltante < VALOR_CERO){
      
        Swal.fire({
          title: ALERTA_ERROR_TRABAJADORES_ADHERIDOS_NO_PERMITIDO.replace(SIGNO_NUMERAL,intTrabajadoresFaltante * MENOS_UNO),
          customClass: {
            title: ALERTA_CLASE,
            confirmButton: ALERTA_CLASE,
            popup: ALERTA_CLASE
          }
        })
        document.getElementById(SECCION_POBLACION).scrollIntoView({ behavior: COMPORTAMIENTO });
      }
    }    
  }

  render() {
    const className = this.state.blnNumDotacionValido ? CLASE_FORM_CONTROL : CLASE_FORM_CONTROL_ERROR;
    let { lstTarjetas, lstCoberturas, valoresUF, objPatrocinador} = this.props;
    let intTamanoFila     = lstTarjetas.length > MAX_FILA_TARJETA ? Number(MAX_FILA_TARJETA * TAMANO_FILA_TARJETA) : Number(lstTarjetas.length * TAMANO_FILA_TARJETA);
    let strClassNameNota = `col-xl-${intTamanoFila} col-lg-${intTamanoFila}`;

    const objClpFormato = new Intl.NumberFormat(FORMATO_CODIGO_ESPANOL, {
      style: FORMATO_ESTILO_MONEDA,
      currency: FORMATO_PESO_CHILENO,
      minimumFractionDigits: DECIMALES_UF,
      maximumFractionDigits: DECIMALES_UF,
    });

    return (
      <React.Fragment>
        <Container>
          <section ref={this.objDesplazamiento} className="poblacion">
            <h1 className="poblacion-titulo">Conoce los resultados de Salud Digital para tu Pyme</h1>
            <p className="poblacion-parrafo">Revisa las diferentes alternativas añadiendo planes extra y encuentra los mejores valores de cobertura</p>
            <h3 className="poblacion-subtitulo">¿Necesitas detallar las cargas de tu organización?</h3>
            <section id="seccion-poblacion" className="poblacion-controles">
              <h3 className="poblacion-controles-titulo">N° de personas que trabajan en su empresa</h3>
              <div className="poblacion-controles-form">
                <div className="form-group">
                  <input
                    name="numeroDotacion"
                    defaultValue={this.state.informacionEmpresa.numeroDotacion}
                    onChange={(objEvento) => {this.validarValorPorCampo(objEvento)}}
                    onKeyPress={soloNumeros}
                    type="number"
                    maxLength="2"
                    minLength="1"
                    min={process.env.REACT_APP_CANTIDAD_MINIMA_TITULARES}
                    max={process.env.REACT_APP_CANTIDAD_MAXIMA_TITULARES}
                    className={className}
                    placeholder=""
                  />
                  {!this.state.blnNumDotacionValido && (
                    <small className="form-text text-danger">{ALERTA_ERROR_NUMERO_DOTACION}</small>
                  )}
                </div>
                { !this.state.blnNumDotacionCambio && (
                  this.state.blnBtnCambiarDistribucionCarga ? (
                    <button type="submit" className="btn btn-primary" onClick={(objEvento) => {this.ocultarDistribucionPorTramo(objEvento);}} >
                    Ocultar distribución
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary" onClick={() => {this.mostrarDistribucionPorTramo(VERDADERO);}} >
                    Cambiar distribución
                    </button>
                  )
                )}
              </div>
            </section>
          </section>
          
          { this.state.blnMostrarDistribucionCarga && (
            <section className="adherencia">
              <Adherencia 
                  trabajadoresAdherido={this.state.poblacion.trabajadoresAdherido}  
                  trabajadoresTotal={this.state.poblacion.trabajadoresTotal}
                  porcentajeAdherencia={this.state.poblacion.porcentajeAdherencia}
                  trabajadoresMinimo={this.state.poblacion.trabajadoresMinimo} />

              <DetalleCarga
                        actualizarDistribucionCarga={this.actualizarDistribucionCarga} 
                        trabajadoresTotal={this.state.poblacion.trabajadoresTotal}
                        blnSeleccionCobertura={VERDADERO}
                        tarjetasCalcular={this.props.tarjetasCalcular} />
            </section>
          )}
        </Container>
        <Container className="no-padding">
          <Row>
            { lstTarjetas.map((objTarjeta, indice) => (
                <Col xs={12} sm={12} md={6} lg={4} xl={4} key={`${indice}`}>
                  <Tarjeta 
                    objTarjeta={objTarjeta} 
                    lstCoberturas={lstCoberturas} 
                    blnSimulador={FALSO}
                    indice={indice}
                    seleccionarCoberturaLista={this.props.seleccionarCoberturaLista}
                    seleccionarCobertura={this.props.seleccionarCobertura}
                    solicitarPlan={this.solicitarPlan}
                    objPatrocinador={objPatrocinador} />
                </Col>
              ))
            }
            <p className={`tarjeta-nota col-md-12 col-sm-12 col-12 ${strClassNameNota}`}>* Precio en pesos referencial calculado en base al valor UF = <strong>{objClpFormato.format(valoresUF.valorUf)}</strong> del día <strong>{valoresUF.fechaUf}</strong></p>
          </Row>
        </Container>
        { this.props.btnNuevaCotizacion && (
          <footer className="wizard-buttons text-center">
            <button type="button" className="btn btn-primary" onClick={() => {this.props.limpiarDatosCotizador()}} >
              Cotizar nueva empresa
            </button>
          </footer>
        )}
      </React.Fragment>
    )
  }
}

const convertirEstadoApropiedad = state => ({
  store: state
});

export default connect(convertirEstadoApropiedad, {getCompanyInformation,guardarPoblacionPorCotizacion})(SeleccionCobertura);
