import {
  GET_PERSONAL_INFORMATION,
  GET_COMPANY_INFORMATION,
  GET_PLAN_INFO,
  GET_PRECOTIZACION,
  GET_REGIONES,
  PREV_PAGE,
  NEXT_PAGE,
  RESET,
  OPEN_MODAL,
  GET_PLAN_SELECTED,
  OBTENER_PRECOTIZACION,
  OBTENER_TRABAJADORES,
  OBTENER_PATROCINADOR,
  OBTENER_TARJETAS,
  OBTENER_DISTRIBUCION_TRAMOS,
  OBTENER_POBLACION,
  OBTENER_COBERTURAS,
  OBTENER_COMBINACION_PLANES,
  OBTENER_UF,
  OBTENER_HISTORIAL_COTIZACIONES,
  OBTENER_EJECUTIVO
} from "../actions/types";

// State inicial
const initialState = {
  informacionPersonal: null,
  informacionEmpresa: null,
  planInfo: null,
  preCotizacion: null,
  plan_selected: {},
  regiones: [],
  page: 0,
  previous_page: 0,
  modal: false,
  trabajadores: null,
  patrocinador: null,
  tarjetas: [],
  coberturas: null,
  tramosDistribucion: null,
  poblacion: null,
  combinacionPlanes: [],
  valoresUF:null,
  cotizacionesHistorial:[],
  ejecutivos:[]
};

export default function(state, action) {
  state = initialState;

  switch (action.type) {
    case GET_PERSONAL_INFORMATION:
      state.informacionPersonal = {
        ...state.informacionPersonal,
        ...action.payload
      };
      return { ...state };
    case GET_COMPANY_INFORMATION:
      state.informacionEmpresa = {
        ...state.informacionEmpresa,
        ...action.payload
      };
      return { ...state };
    case GET_PLAN_INFO:
      state.planInfo = {
        ...state.planInfo,
        ...action.payload
      };
      return { ...state };
    case GET_PLAN_SELECTED:
      state.plan_selected = action.payload;
      return { ...state };
    case GET_PRECOTIZACION:
      state.preCotizacion = action.payload;
      return { ...state };
    case GET_REGIONES:
      state.regiones = action.payload;
      return { ...state };
    case RESET:
      state.informacionPersonal = null;
      state.informacionEmpresa = null;
      state.page = 1;
      state.previous_page = 0;
      state.plan_selected = {};
      state.tramosDistribucion = null;
      state.poblacion = null;
      state.historialCotizaciones = []
      return { ...state };
    case NEXT_PAGE:
      state.previous_page = state.page;
      state.page += 1;
      return { ...state };
    case PREV_PAGE:
      state.previous_page = state.page;
      state.page -= 1;
      return { ...state };
    case OPEN_MODAL:
      state.modal = !state.modal;
      return { ...state };
    case OBTENER_PRECOTIZACION:
      state.objetoPrecotizacion = action.payload;
      return { ...state };
    case OBTENER_TRABAJADORES:
      state.trabajadores = action.payload;
      return { ...state };
    case OBTENER_PATROCINADOR:
      state.patrocinador = action.payload;
      return { ...state };
    case OBTENER_TARJETAS:
      state.tarjetas = action.payload;
      return { ...state };
    case OBTENER_COBERTURAS:
      state.coberturas = action.payload;
      return { ...state };
    case OBTENER_DISTRIBUCION_TRAMOS:
      state.tramosDistribucion = action.payload;
      return { ...state };
    case OBTENER_POBLACION:
      state.poblacion = action.payload;
      return { ...state };
    case OBTENER_COMBINACION_PLANES:
      state.combinacionPlanes = action.payload;
      return { ...state };
    case OBTENER_UF:
      state.combinacionPlanes = action.payload;
      return { ...state };
    case OBTENER_HISTORIAL_COTIZACIONES:
      state.cotizacionesHistorial = action.payload;
      return { ...state };
    case OBTENER_EJECUTIVO:
      state.ejecutivos = action.payload;
      return { ...state };
    default:
      return state;
  }
}
