import React from 'react'
import { CLASE_ABIERTO, CLASE_CERRADO } from '../../../utils/Constants';

export const Historial = ({ objEstado: { cotizacionesHistorial, blnMostrarMenu }, mostrarHistorial}) => {

  let intCotizacionesCantidad   = cotizacionesHistorial.length;
  let strClase                  = blnMostrarMenu ? CLASE_ABIERTO : CLASE_CERRADO;

  return (
    <>
      <section className={`historial no-gutters animated fadeIn ${strClase}`} >
        <span className="historial-cantidad">
          {intCotizacionesCantidad}
        </span>
        <button className="btn btn-sm historial-btn" type="button" onClick={() => {mostrarHistorial(blnMostrarMenu)}}>
        { blnMostrarMenu ? (
          <i className="fa fa-chevron-right mr-1" aria-hidden="true"></i> ) : (
          <i className="fa fa-chevron-left mr-1"aria-hidden="true"></i>
        )}
        </button>
        <ul className="historial-lista">
          <li className="historial-lista-titulo">
            Has cotizado correctamente 
            <small>Te hemos enviado un correo electrónico con toda la información sobre tu cotización, en breve un ejecutivo de ventas se contactará contigo, gracias por tu preferencia</small></li>
        { cotizacionesHistorial.map((objCotizacion, index) => (
          <li key={index}>
            <span><strong>Nombre plan:</strong> {objCotizacion.planNombre}</span>
            <span><strong>Folios:</strong> {objCotizacion.folio}</span>
          </li>
        ))}
        </ul>
      </section>
    </>
  )
}
