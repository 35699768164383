import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./app/assets/css/animate.css";
import "./app/assets/css/hover-min.css";
import "./app/assets/css/font-awesome.min.css";
import Contenedor from "./app/components/Contenedor";
import { Cargando } from "./app/components/Cargando";
import { library } from "@fortawesome/fontawesome-svg-core";
import TagManager from "react-gtm-module";
import {
  faStroopwafel,
  faCheckCircle,
  faTimesCircle,
  faAllergies,
  faCheck,
  faAddressCard,
  faBuilding,
  faHandHoldingHeart,
  faBars,
  faEllipsisV,
  faHome,
  faHeartbeat,
  faInfoCircle,
  faHospital,
  faHeart,
  faTimes,
  faLink,
  faMinus,
  faPlus,
  faArrowRight,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faStroopwafel,
  faCheckCircle,
  faTimesCircle,
  faAllergies,
  faCheck,
  faAddressCard,
  faBuilding,
  faHandHoldingHeart,
  faBars,
  faEllipsisV,
  faHome,
  faHeartbeat,
  faInfoCircle,
  faHospital,
  faHeart,
  faTimes,
  faLink,
  faMinus,
  faPlus,
  faArrowRight,
  faArrowLeft
);

export const App = (objPropiedad) => {

  const objPatrocinador            = objPropiedad.objPatrocinador;
  const [blnCargando, setCargando] = useState(true);

  const mostrarCargando = (blnVisualizacion = true) => {
    setCargando(blnVisualizacion);
  }

  useEffect(() => {    
    TagManager.initialize({
      gtmId: process.env.REACT_APP_KEY_GOOGLE_TAG_MANAGER,
      dataLayer: {},
    });
  },[]);

  return (
    <Provider store={store}>
      <Contenedor objPatrocinador={objPatrocinador} mostrarCargando={mostrarCargando} />
      { blnCargando && (
        <Cargando strLogoBlanco={objPatrocinador.logoBlanco} />
      )}
    </Provider>
  )
}