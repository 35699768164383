import ServicesFactory from "../config/servicesFactory";
import { getGatewayToken } from "./QuotationService";

let services = new ServicesFactory();

export async function buscarPatrocinadorPorCodigoSitio(codigo) {

  await getGatewayToken()

  if (codigo !== undefined){
    try {
      return await services
        .get(`${process.env.REACT_APP_API_URL_BACKSUSCRIPCION}/patrocinador/buscarPorCodigoSitio/${codigo}`,false)
        .then( res => {
  
          if (undefined !== res && null !== res.status && 200 === res.status) {
            if (res.data.status.codigo === "001") {
  
              return res.data;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
    } catch (error) {
      return false;
    }
  }
  else{
    return false;
  }
}