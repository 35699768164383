import React, { Component } from "react";
import { Calendar } from "primereact/calendar";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Adherencia from "./Adherencia/Adherencia";
import DetalleCarga from "./DetalleCarga/DetalleCarga";
import Swal from 'sweetalert2';
import {
    buscarCotizacionAdherencia,
} from "../../../../services/QuotationService";
import {
    ID_SUDPRODUCTO,
    VALOR_CIEN,
    FECHA_VIGENCIA_RANGO,
    VALOR_INICIAL,
    MENOS_UNO,
    DIAS_SEMANA,
    NOMBRE_DIAS,
    NOMBRE_DIAS_CORTO,
    NOMBRE_DIAS_LETRA,
    NOMBRE_MESES,
    NOMBRE_MESES_CORTO,
    TEXTO_HOY,
    TEXTO_LIMPIAR,
    CALENDARIO_FORMATO,
    TITULO_SEMANA,
    NUMERO_DOTACION_INICIAL,
    TEXTO_VACIO,
    CLASE_DESHABILITADO,
    CLASE_CALENDARIO_FECHA_SELECCIONADA,
    SELECTOR_CALENDARIO_ANIO,
    SELECTOR_CALENDARIO_MES,
    EVENTO_CAMBIAR,
    SELECTOR_CALENDARIO_FECHA_SELECCIONADA,
    ALERTA_CLASE,
    SIGNO_NUMERAL,
    ALERTA_ERROR_TRABAJADORES_ADHERIDOS_NO_PERMITIDO,
    ALERTA_ERROR_CANTIDAD_FALTANTA_TRABAJADORES,
    NUMERO_VACIO,
    VERDADERO,
    FALSO
} from "../../../../utils/Constants";
import {
    getCompanyInformation,
    guardarPoblacionPorCotizacion,
    getPreCotizacion,
    guardarDistribucionTramosPorCotizacion
} from "../../../../actions/QuoteActions";
import {
    required
} from "../../../../utils/Functions";
import TagManager from "react-gtm-module";

class DistribucionDotacion extends Component {

    constructor(props){
        super(props);
        this.objDesplazamiento = React.createRef();
        this.state = {
            blnFechaVigencia: VERDADERO,
            poblacion: {
                trabajadoresTotal: NUMERO_DOTACION_INICIAL,
                porcentajeAdherencia:null,
                trabajadoresMinimo:null,
                trabajadoresFaltante: NUMERO_VACIO,
                trabajadoresAdherido: NUMERO_VACIO,
                fechaVigencia: null
            },
            calendario:{
                fechaMinima: null,
                fechaMaxima: null,
                rangoAnio: TEXTO_VACIO,
                espanolConfig:{
                    firstDayOfWeek: DIAS_SEMANA,
                    dayNames: NOMBRE_DIAS,
                    dayNamesShort: NOMBRE_DIAS_CORTO,
                    dayNamesMin: NOMBRE_DIAS_LETRA,
                    monthNames: NOMBRE_MESES,
                    monthNamesShort: NOMBRE_MESES_CORTO,
                    today: TEXTO_HOY,
                    clear: TEXTO_LIMPIAR,
                    dateFormat: CALENDARIO_FORMATO,
                    weekHeader: TITULO_SEMANA
                }
            },
            preCotizacion: {
                idPrecotizacion: null,
                idRegion: null,
                idEmpresa: null,
                fechaInicioVigencia: null
            },
            tramosDistribucion:{
                sinCarga: NUMERO_VACIO,
                unaCarga: NUMERO_VACIO,
                dosOmasCarga: NUMERO_VACIO,
                total: NUMERO_VACIO
            }
        }
        TagManager.dataLayer({
            dataLayer: {
              event: "traza",
              event_category: "Cotizacion",
              event_label: "Cotización paso 2 - Cantidad de Cargas",
            },
          });  
    }

    async componentDidMount(){

        let { informacionEmpresa, poblacion, preCotizacion } = this.props.store.reducer;
        const { objeto : {porcentajeAdherencia} } = await buscarCotizacionAdherencia(informacionEmpresa.numeroDotacion,ID_SUDPRODUCTO);
        let datFechaVigenciaMinima                = new Date();
        let datFechaVigenciaMaxima                = new Date();
        let intProximoAnio                        = datFechaVigenciaMinima.getFullYear() + FECHA_VIGENCIA_RANGO;
        let objPoblacion                          = {...this.state.poblacion};
        let objPreCotizacion;

        datFechaVigenciaMaxima.setFullYear(intProximoAnio);

        let objCalendario = {
            ...this.state.calendario,
            fechaMinima: datFechaVigenciaMinima,
            fechaMaxima: datFechaVigenciaMaxima,
            rangoAnio: `${datFechaVigenciaMinima.getFullYear()}:${intProximoAnio}`
        }

        if (poblacion !== null) {
            objPoblacion = { ...poblacion};
        }

        objPoblacion.porcentajeAdherencia = porcentajeAdherencia;

        if (informacionEmpresa !== null) {
            objPoblacion.trabajadoresTotal  = informacionEmpresa.numeroDotacion;
            objPoblacion.trabajadoresMinimo = Math.ceil( informacionEmpresa.numeroDotacion * (porcentajeAdherencia / VALOR_CIEN))
        }

        if (preCotizacion !== null) {
            objPreCotizacion = { ...preCotizacion};
        }

        this.setState({poblacion: objPoblacion, calendario : objCalendario, preCotizacion: objPreCotizacion});
        this.configurarCalendario();
        this.props.mostrarCargando(FALSO);
        if (this.objDesplazamiento.current) this.objDesplazamiento.current.scrollIntoView();
    }

    async componentDidUpdate(prevProps){

        let { poblacion } = this.props.store.reducer;

        if ( poblacion !== null && poblacion !== undefined) {
            
            let intPevTrabajadoresTotal = poblacion.trabajadoresTotal;
            let intTrabajadoresTotal    = this.props.store.reducer.informacionEmpresa.numeroDotacion;

            if (intPevTrabajadoresTotal !== intTrabajadoresTotal) {
                const { objeto : {porcentajeAdherencia} } = await buscarCotizacionAdherencia(intTrabajadoresTotal,ID_SUDPRODUCTO);

                poblacion.porcentajeAdherencia = porcentajeAdherencia;
                poblacion.trabajadoresTotal    = intTrabajadoresTotal;
                poblacion.trabajadoresMinimo   = Math.ceil( intTrabajadoresTotal * (porcentajeAdherencia / VALOR_CIEN));

                this.setState({poblacion});
            }
        }

    }

    actualizarDistribucionCarga = (intTrabajadoresAdherido) => {

        let { poblacion } = this.state;
        poblacion.trabajadoresAdherido = intTrabajadoresAdherido;

        this.props.guardarPoblacionPorCotizacion(poblacion);
        this.setState({poblacion})       
    }

    procesarBtnRetroceder(objEvento) {
        objEvento.preventDefault();

        let { tramosDistribucion }   = this.props.store.reducer;
        let { poblacion }            = this.state;
        poblacion.tramosDistribucion = tramosDistribucion;

        this.props.guardarPoblacionPorCotizacion(poblacion);
        this.props.prevPage();
    }

    async procesarBtnContinuar(objEvento) {
        objEvento.preventDefault();
        this.props.mostrarCargando(VERDADERO);

        let { poblacion } = this.state;
        let { tramosDistribucion } = this.props.store.reducer;
        let intRequerido = required(document.querySelectorAll(".seleccionar-vigencia input:required"));

        if (Number(poblacion.trabajadoresTotal) === Number(poblacion.trabajadoresAdherido) &&
            Number(intRequerido) === NUMERO_VACIO) {

            poblacion.tramosDistribucion = tramosDistribucion;

            this.setState({ blnFechaVigencia : VERDADERO });

            await this.enviarPreCotizacionPorCotizacion(poblacion);
            this.props.tarjetasCalcular();
            this.props.guardarPoblacionPorCotizacion(poblacion);
            this.props.nextPage();

        }
        else{
            this.props.mostrarCargando(FALSO);

            if (intRequerido > NUMERO_VACIO ) {
                this.setState({ blnFechaVigencia : FALSO });
            }

            let intTrabajadoresFaltante = Number(poblacion.trabajadoresTotal - poblacion.trabajadoresAdherido);
    
            if (Number(poblacion.trabajadoresTotal) !== Number(poblacion.trabajadoresAdherido) &&
                intTrabajadoresFaltante >= VALOR_INICIAL){
                
                Swal.fire({
                  title: ALERTA_ERROR_CANTIDAD_FALTANTA_TRABAJADORES.replace(SIGNO_NUMERAL,intTrabajadoresFaltante),
                  customClass: {
                    title: ALERTA_CLASE,
                    confirmButton: ALERTA_CLASE,
                    popup: ALERTA_CLASE
                  }
                })
            }

            if (Number(poblacion.trabajadoresTotal) !== Number(poblacion.trabajadoresAdherido) &&
                intTrabajadoresFaltante < VALOR_INICIAL){
            
            Swal.fire({
              title: ALERTA_ERROR_TRABAJADORES_ADHERIDOS_NO_PERMITIDO.replace(SIGNO_NUMERAL,intTrabajadoresFaltante * MENOS_UNO),
              customClass: {
                title: ALERTA_CLASE,
                confirmButton: ALERTA_CLASE,
                popup: ALERTA_CLASE
              }
            })
        }
            return;
        }
    }

    async enviarPreCotizacionPorCotizacion(poblacion){

        let { informacionEmpresa } = this.props.store.reducer;
        let { preCotizacion }      = this.state;
        const objPrecotizacion     = {
          preCotizacion: {
            ...preCotizacion,
            idRegion: informacionEmpresa.idRegion,
            idEmpresa: informacionEmpresa.idEmpresa,
            fechaInicioVigencia: poblacion.fechaVigencia
          }
        };
        this.props.getPreCotizacion(objPrecotizacion.preCotizacion);
    }

    cambiarFechaVigencia = async (objEvento) => {

        let { calendario: { fechaMaxima} } = this.state;

        if (fechaMaxima !== null && (objEvento.value !== null && objEvento.value !== undefined )) {

            let blnActualizarFecha = VERDADERO;

            if (Number(objEvento.value.getFullYear()) === fechaMaxima.getFullYear()) {

                if (objEvento.value.getMonth() <= fechaMaxima.getMonth() && 
                    document.querySelector(SELECTOR_CALENDARIO_FECHA_SELECCIONADA) === null) {
                    blnActualizarFecha = VERDADERO;  
                }
                else{
                    objEvento.value = null;
                    blnActualizarFecha = FALSO;
                }
            }

            if (blnActualizarFecha) {
                let objPoblacion = {
                    ...this.state.poblacion,
                    fechaVigencia: objEvento.target.value
                }
        
                this.setState({ poblacion: objPoblacion, blnFechaVigencia : blnActualizarFecha });
            }
        }
    };

    configurarCalendario = () => {
        this.validarMesesPorAnio();
        document.querySelector(SELECTOR_CALENDARIO_ANIO).addEventListener(EVENTO_CAMBIAR, this.validarMesesPorAnio);
    }

    validarMesesPorAnio = () => {
        let { calendario: { fechaMinima, fechaMaxima} } = this.state;
        let lstMes  = document.querySelectorAll(SELECTOR_CALENDARIO_MES);
        let objAnio = document.querySelector(SELECTOR_CALENDARIO_ANIO);

        if (objAnio !== null && fechaMinima !== null && fechaMaxima !== null && lstMes !== null) {
            if (Number(objAnio.value) === fechaMinima.getFullYear()) {
                
                lstMes.forEach((objMes, intIndice) => {
                    if (intIndice <= fechaMinima.getMonth()) {
                        if (objMes.classList.contains(CLASE_CALENDARIO_FECHA_SELECCIONADA)) {
                            objMes.classList.remove(CLASE_CALENDARIO_FECHA_SELECCIONADA);
                        }
                        objMes.classList.add(CLASE_DESHABILITADO);
                    }
                    else{
                        objMes.classList.remove(CLASE_DESHABILITADO);
                        objMes.disabled = FALSO;
                    }
                })
            }
    
            if (Number(objAnio.value) === fechaMaxima.getFullYear()) {

                lstMes.forEach((objMes, intIndice) => {
                    
                    if (intIndice > fechaMaxima.getMonth()) {
                        if (objMes.classList.contains(CLASE_CALENDARIO_FECHA_SELECCIONADA)) {
                            objMes.classList.remove(CLASE_CALENDARIO_FECHA_SELECCIONADA);
                        }
                        objMes.classList.add(CLASE_DESHABILITADO);
                        objMes.disabled = VERDADERO;
                    }
                    else{
                        objMes.classList.remove(CLASE_DESHABILITADO);
                        objMes.disabled = FALSO;
                    }
                })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <section ref={this.objDesplazamiento} className="seleccionar-vigencia">
                    <h3>¿Desde cuando te gustaría que el seguro estuviera vigente?</h3> 
                    <Calendar
                        id="fechaVigencia"
                        placeholder="Selecciona un mes y año"
                        minDate={this.state.calendario.fechaMinima}
                        maxDate={this.state.calendario.fechaMaxima}
                        locale={this.state.calendario.espanolConfig}
                        value={this.state.poblacion.fechaVigencia}
                        onChange={(objEvento) => { this.cambiarFechaVigencia(objEvento)}}
                        yearRange={this.state.calendario.rangoAnio}
                        onFocus={this.validarMesesPorAnio()}
                        dateFormat="mm/yy"
                        view="month"
                        className={!this.state.blnFechaVigencia ? "text-danger":""}
                        yearNavigator
                        readOnlyInput
                        showIcon
                        required
                            />
                    {!this.state.blnFechaVigencia && (
                      <small className="form-text text-danger">
                        Introduzca una fecha de vigencia
                      </small>
                    )}
                </section>
                <section className="adherencia">
                    <h3>¿Cuántas personas tienen cargas?</h3>
                    
                    <Adherencia trabajadoresAdherido={this.state.poblacion.trabajadoresAdherido}  
                                trabajadoresTotal={this.state.poblacion.trabajadoresTotal}
                                porcentajeAdherencia={this.state.poblacion.porcentajeAdherencia}
                                trabajadoresMinimo={this.state.poblacion.trabajadoresMinimo}/>
                    <DetalleCarga
                        actualizarDistribucionCarga={this.actualizarDistribucionCarga} 
                        trabajadoresTotal={this.state.poblacion.trabajadoresTotal}
                        blnSeleccionCobertura={FALSO}
                        tarjetasCalcular={this.props.tarjetasCalcular} />
                </section>

                <footer className="wizard-buttons text-center">
                    <button type="submit" className="btn btn-primary" onClick={(objEvento) => {this.procesarBtnContinuar(objEvento)}}>
                        Continuar
                        <FontAwesomeIcon icon="arrow-right" />
                    </button>
                    <button type="button" className="btn btn-atras" onClick={(objEvento) => {this.procesarBtnRetroceder(objEvento)}}>
                        <FontAwesomeIcon icon="arrow-left" />
                    </button>
                </footer>
            </React.Fragment>
        );
    }
}

const convertirEstadoApropiedad = state => ({
  store: state
});


export default connect(convertirEstadoApropiedad, {
    getCompanyInformation,
    guardarPoblacionPorCotizacion,
    getPreCotizacion,
    guardarDistribucionTramosPorCotizacion
  })(DistribucionDotacion);