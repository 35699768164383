import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { soloNumeros } from "../../../../../utils/Functions";

export default function Tramo({ objTramo, agregarTrabajadorPorTramo, quitarTrabajadorPorTramo, cambiarCargaPorTramo, blnSeleccionCobertura }) {
    return (
        <section className="tramo">
            <figure className="tramo-icono">
                {objTramo.icono}
            </figure>
            <div className="tramo-detalle">
                <h4 className="tramo-titulo">{objTramo.titulo}</h4>
                <div className="tramo-controles">
                    <input 
                        id={objTramo.id}
                        name={objTramo.id}
                        type="number" 
                        className="tramo-campo form-control" 
                        value={objTramo.cantidad} 
                        onChange={(objEvento)=> {cambiarCargaPorTramo(objEvento,objTramo,blnSeleccionCobertura)}}
                        onKeyPress={soloNumeros}  />
                    <div className="tramo-botones">
                        <button type="button" className="tramo-boton" onClick={() => agregarTrabajadorPorTramo(objTramo)}>
                            <FontAwesomeIcon icon={faPlus}/>
                        </button>
                        <button type="button" className="tramo-boton mt-1"  onClick={() => quitarTrabajadorPorTramo(objTramo)}>
                            <FontAwesomeIcon icon={faMinus}/>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}