import React from 'react'
import { 
  CODIGO_COBERTURA_AMPLIADO, 
  CODIGO_COBERTURA_DENTAL, 
  CODIGO_COBERTURA_MEDICAMENTO, 
  CODIGO_COBERTURA_VIDA 
} from '../../../../../utils/Constants'
import { Dental, Medicamentos, Salud, Vida } from '../../../../../utils/IconosSvg'

export const CoberturaIcono = ({ grupo }) => {
  return (
    <>
    {{ 
      [CODIGO_COBERTURA_AMPLIADO]: <Salud />,
      [CODIGO_COBERTURA_DENTAL]: <Dental />,
      [CODIGO_COBERTURA_MEDICAMENTO] : <Medicamentos />,
      [CODIGO_COBERTURA_VIDA] : <Vida />
    }[grupo]}
    </>
  )
}
