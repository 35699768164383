import React from 'react'
import { CoberturaCasillaSeleccion } from './CoberturaCasilla';
import { CoberturaLista } from './CoberturaLista';

export const Cobertura = (objProps) => {

  let strClassName    = objProps.objCobertura.blnSeleccionada ? "tarjeta-cobertura" : "tarjeta-cobertura tarjeta-cobertura--notselect";
  strClassName        = objProps.objCobertura.principal ? strClassName+" tarjeta-cobertura-principal" : strClassName;
  strClassName        = objProps.objCobertura.independiente ? strClassName : strClassName+" tarjeta-cobertura-bloqueada";
  let blnDesabilidada = !objProps.objCobertura.independiente;
  let blnSeleccionada = !objProps.objCobertura.blnSeleccionada;

  if( objProps.objCobertura.blnLstCobertura )
    return (
      <>
      <CoberturaLista
        objCobertura={objProps.objCobertura}
        blnSimulador={objProps.blnSimulador}
        objTarjeta={objProps.objTarjeta}
        coberturaId={objProps.coberturaId}
        strClassName={strClassName}
        seleccionarCoberturaLista={objProps.seleccionarCoberturaLista}
        blnDesabilidada={blnDesabilidada}
          />
      </>
    );
  else
    return(
      <>
      <CoberturaCasillaSeleccion 
        objCobertura={objProps.objCobertura}
        blnSimulador={objProps.blnSimulador}
        idTarjeta={objProps.objTarjeta.id}
        coberturaId={objProps.coberturaId}
        strClassName={strClassName} 
        seleccionarCobertura={objProps.seleccionarCobertura}
        blnDesabilidada={blnDesabilidada}
        blnSeleccionada={blnSeleccionada}
        />
      </>
    );
}
