export const CANAL_VENTA_COTIZADOR = 1;
export const USUARIO_CREA_SISTEMA = 1;
export const TIPO_INCORPORACION_DIGITAL = 1;
export const ID_SUDPRODUCTO = 24;
export const DIA_DIEZ = 10;
export const DIAS_SEMANA = 1;
export const MES_ENERO = 0;
export const MES_DICIEMBRE = 11;
export const MES_OCTUBRE = 9;
export const NOMBRE_DIAS = [
  "domingo",
  "lunes",
  "martes",
  "miércoles",
  "jueves",
  "viernes",
  "sábado",
];
export const NOMBRE_DIAS_CORTO = [
  "dom",
  "lun",
  "mar",
  "mié",
  "jue",
  "vie",
  "sáb",
];
export const NOMBRE_DIAS_LETRA = ["D", "L", "M", "X", "J", "V", "S"];
export const NOMBRE_MESES = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];
export const NOMBRE_MESES_CORTO = [
  "ene",
  "feb",
  "mar",
  "abr",
  "may",
  "jun",
  "jul",
  "ago",
  "sep",
  "oct",
  "nov",
  "dic",
];
export const CALENDARIO_FORMATO = "dd-mm-yy";
export const TITULO_SEMANA = "Sm";
export const TEXTO_HOY = "Hoy";
export const TEXTO_LIMPIAR = "Limpiar";
export const CODIGO_COBERTURA_MEDICAMENTO = "MDC";
export const CODIGO_COBERTURA_DENTAL = "DNT";
export const CODIGO_COBERTURA_AMPLIADO = "AMP";
export const CODIGO_COBERTURA_VIDA = "VD";
export const CODIGO_PLAN_ESPEJO = "ESP";
export const TOPE_DEFECTO = "Tope 0 UF";
export const RANGO_MINIMO = 1;
export const RANGO_MAXIMO = 10;
export const VIDA = "Vida";
export const COBERTURA_GENERAL_VIDA = 4;
export const LISTA_VACIA = 0;
export const PRIMERA_POSICION_LISTA = 0;
export const LARGO_COTIZACION_VACIA = 0;
export const TAMANO_UNO = 1;
export const SIGNO_MAS = "+";
export const SIGNO_GUION = "-";
export const SIGNO_PUNTO = ".";
export const SIGNO_NUMERAL = "#";
export const SIGNO_PORCENTAJE = "%";
export const ESPACIO_BLANCO = " ";
export const TEXTO_VACIO = "";
export const TEXTO_CERO = "0";
export const TEXTO_SELECCIONE = "Seleccione...";
export const NUMERO_VACIO = 0;
export const VALOR_CIEN = 100;
export const VALOR_CERO = 0;
export const POSICION_CERO = 0;
export const POSICION_UNO = 1;
export const TAMANO_DIES = 10;
export const TAMANO_OCHO = 8;
export const MENOS_UNO = -1;
export const TRAMO_SIN_CARGA_ID = "tramo1";
export const TRAMO_SIN_CARGA_TITULO = "Sin carga";
export const TRAMO_UNA_CARGA_ID = "tramo2";
export const TRAMO_UNA_CARGA_TITULO = "Solo una carga";
export const TRAMO_DOS_CARGA_ID = "tramo3";
export const TRAMO_DOS_CARGA_TITULO = "Más de 2 cargas";
export const TRAMO_DOS_CARGA_MINIMO_CARGA = 2;
export const TRAMO_MINIMA_SUMA_O_RESTA = 1;
export const TRAMO_ID_SIN_CARGA = 1;
export const TRAMO_ID_UNA_CARGA = 2;
export const TRAMO_ID_DOS_CARGA = 3;
export const TRAMO_ERROR_TRABAJADORES_MAXIMO =
  "No puede adherir mas de # trabajadores";
export const FECHA_VIGENCIA_RANGO = 1;
export const COTIZADOR_PASO_SIMULADOR = 0;
export const COTIZADOR_PASO_INFORMACION_PERSONAL = 1;
export const COTIZADOR_PASO_INFORMACION_PERSONAL_CARGO = "Delegado empresa";
export const COTIZADOR_PASO_CARGAS = 2;
export const COTIZADOR_PASO_COBERTURAS = 3;
export const MAX_FILA_TARJETA = 3;
export const TAMANO_FILA_TARJETA = 4;
export const SECCION_POBLACION = "seccion-poblacion";
export const COMPORTAMIENTO = "smooth";
export const COTIZADOR_PASOS = [
  { numero: 0, nombre: "Simulador" },
  { numero: 1, nombre: "Datos personales" },
  { numero: 2, nombre: "Cantidad de cargas" },
  { numero: 3, nombre: "Selección de coberturas" },
];
export const POSICION_COBERTURA_CHECKBOX = 0;
export const ID_MONEDA_UF = 3;
export const VALOR_INICIAL = 0;
export const DECIMALES_CLP = 0;
export const DECIMALES_UF = 2;
export const FORMATO_CODIGO_ESPANOL = "es-ES";
export const FORMATO_ESTILO_MONEDA = "currency";
export const FORMATO_PESO_CHILENO = "CLP";
export const FORMATO_FECHA_TEXTO = "long";
export const FORMATO_FECHA_NUMERO = "numeric";
export const PRIMER_CARACTER = 0;
export const TERCER_CARACTER = 3;
export const ALERTA_ERROR_NUMERO_DOTACION = `Introduce un número no menor que ${process.env.REACT_APP_CANTIDAD_MINIMA_TITULARES} ni mayor que ${process.env.REACT_APP_CANTIDAD_MAXIMA_TITULARES}`;
export const ALERTA_ERROR_CANTIDAD_FALTANTA_TRABAJADORES =
  "Hay # trabajador/es que no se han adherido";
export const ALERTA_ERROR_TRABAJADORES_ADHERIDOS_NO_PERMITIDO =
  "Se han aderido # trabajador/es de más";
export const ALERTA_ERROR_FALTA_ADHERIR_TRABAJADORES =
  "Hay trabajadores que no se han adherido";
export const ALERTA_ERROR_CONEXION_SERVIDORES =
  "No hemos podido conectar con nuestros servidores, solicite su cotización nuevamente";
export const ALERTA_ERROR_COBERTURAS_FALTANTES =
  "Debe escoger las siguientes coberturas: ";
export const ALERTA_TIPO_ERROR = "error";
export const ALERTA_CLASE = "alert";
export const ALERTA_POSICION_SUPERIOR_DERECHA = "top-end";
export const CLASE_ABIERTO = "abierto";
export const CLASE_CERRADO = "cerrado";
export const CLASE_COBERTURA_ERROR = "tarjeta-cobertura--error";
export const CLASE_FORM_CONTROL = "form-control";
export const CLASE_FORM_CONTROL_ERROR = "form-control is-invalid";
export const CLASE_CALENDARIO_FECHA_SELECCIONADA = "p-highlight";
export const CLASE_DESHABILITADO = "disabled";
export const CLASE_ADHERENCIA_FALTANTE = "adherencia-faltantes";
export const CLASE_ADHERENCIA_FALTANTE_ERROR = "adherencia-faltantes--error";
export const CLASE_ADHERENCIA_FALTANTE_ADVERTENCIA =
  "adherencia-faltantes--war";
export const SELECTOR_CALENDARIO_FECHA_SELECCIONADA = ".p-highlight";
export const SELECTOR_CALENDARIO_ANIO = ".p-datepicker-year";
export const SELECTOR_CALENDARIO_MES = ".p-monthpicker-month";
export const EVENTO_CAMBIAR = "change";
export const ELEMENTO_COBERTURA_VIDA_ID = "#cobertura-vida-";
export const ELEMENTO_COBERTURA_DENT_ID = "#cobertura-dental-";
export const ELEMENTO_COBERTURA_AMPL_ID = "#cobertura-ampliacion-";
export const NOMBRE = "nombre";
export const APELLIDOS = "apellidos";
export const CORREO = "email";
export const RUT = "rut";
export const TELEFONO = "telefono";
export const RAZON_SOCIAL = "razonSocial";
export const RUT_EMPREZA = "rutEmpresa";
export const NUMERO_DOTACION = "numeroDotacion";
export const NUMERO_DOTACION_INICIAL = 5;
export const VERDADERO = true;
export const FALSO = false;
export const TIPO_STRING = "string";
export const TIPO_VIDA = "Vida";
export const TIPO_SALUD = "Salud";
export const POSICION_INICIAL_RUT = 4;
export const POSICION_FINAL_RUT = 3;
export const EXPRESION_REGULAR_RUT = /^0+|[^0-9kK]+/g;
export const ENDPOINT_LISTAR_PLANTILLA_POR_PATROCINADOR =
  "listarPorIdPatrocinador";
export const ANCHO_MINIMO_PANTALLA = 768;
export const ORDENAMIENTO_VALORES_IGUALES = 0;
export const ORDENAMIENTO_VALOR_MENOR = 1;
export const ORDENAMIENTO_VALOR_MAYOR = -1;
export const PARAMETRO_PLANES = "planes";
export const COBERTURA_DEFECTO = {
  id: NUMERO_VACIO,
  tipo: "",
  codigo: CODIGO_COBERTURA_VIDA,
  nombre: TEXTO_SELECCIONE,
  tope: TOPE_DEFECTO,
  primaTotal: NUMERO_VACIO,
  primaTitular: NUMERO_VACIO,
  primaCarga1: NUMERO_VACIO,
  primaCarga2: NUMERO_VACIO,
  primaCarga3: NUMERO_VACIO,
};
export const GRUPO_COBERTURA_DEFECTO = {
  grupo: CODIGO_COBERTURA_VIDA,
  requerido: FALSO,
  principal: VERDADERO,
  cobertura: [{ ...COBERTURA_DEFECTO }],
};
export const PATROCINADOR_CLASIFICACION_EJECUTIVOS = 3;
export const PATROCINADOR_SIN_EJECUTIVO = {
  id: 0,
  nombre: "Sin Ejecutivo",
  apellidoPaterno: null,
  apellidoMaterno: null,
  clasificacion: { idRepresentanteClasificacion: 0 },
};
export const PATROCINADOR_DEFECTO = 0;
export const PATROCINADORES_CONFIGURACION = [
  {
    id: 0,
    nombre: "VC",
    logoColor: "logo-vc.png",
    logoBlanco: "logo-vc-blanco.png",
    favicon: "favicon-vc.png",
    token: "TK_CotizadorSinPatrocinador",
    seleccionarRegion: true,
    seleccionarEjecutivo: false,
    tieneMaximoPoblacion: true,
    nombreAplicacion: "Pyme Digital",
    modalColaboradoresCantidad: `entre 5 y 100`,
    escogerPlanes: true,
    planes: [
      {
        plan: "PFRT",
        tema: "./utils/TemaPreferente",
        color: "#e38100",
        anuncio: "banner-VC.jpg",
        nombre: "Planes Pyme Digital",
        url: "?planes=PFRT",
        mostrar: true,
      },
      {
        plan: "CRRD",
        tema: "./utils/TemaCerrado",
        color: "#419798",
        anuncio: "banner-RS.jpg",
        nombre: "Planes RedSalud Pyme Digital",
        url: "?planes=CRRD",
        mostrar: false,
      },
    ],
  },
  {
    id: 1,
    nombre: "TALANA",
    logoColor: "logo-talana.png",
    logoBlanco: "logo-talana-blanco.png",
    favicon: "favicon-talana.png",
    token: "TK_CotizadorTalana",
    seleccionarRegion: false,
    seleccionarEjecutivo: false,
    tieneMaximoPoblacion: false,
    nombreAplicacion: "Pyme Talana",
    modalColaboradoresCantidad: `con más de 5 `,
    escogerPlanes: false,
    planes: [
      {
        plan: "TLN",
        tema: "./utils/TemaTalana",
        color: "#0081FC",
        anuncio: "banner-talana.png",
        nombre: "Planes Talana",
        url: "?planes=TLN",
        mostrar: true,
      },
    ],
  },
  {
    id: 2,
    nombre: "MUTUAL",
    logoColor: "logo-vc.png",
    logoBlanco: "logo-mutual.png",
    favicon: "favicon-mutual.ico",
    token: "TK_CotizadorMutual",
    seleccionarRegion: false,
    seleccionarEjecutivo: true,
    tieneMaximoPoblacion: false,
    nombreAplicacion: "Pyme Mutual",
    modalColaboradoresCantidad: `con más de 5 `,
    escogerPlanes: false,
    planes: [
      {
        plan: "MTL",
        tema: "./utils/TemaMutual",
        color: "#c8d644",
        anuncio: "banner-mutual.jpg",
        nombre: "Planes Mutual",
        url: "?planes=MTL",
        mostrar: true,
      },
    ],
  },
];

//EXPRESIONES REGULARES
export const EXP_CARACTERES_ESPECIALES_AMPERSAND =
  /[^a-zA-Z0-9\\u00C0-\u017Fs&/\s{2,}/g]+/g;
export const EXP_CARACTERES_ESPECIALES =
  /[^a-zA-Z0-9\\u00C0-\u017Fs/\s{2,}/g]+/g;
export const EXP_ESPACIOS = /\s{2,}/g;
export const EXP_SALTO_LINEAS = /\n/g;
export const EXP_SOLO_NUMERO = /\d/g;
export const STRING_VACIO = "";
export const ESPACIO_VACIO = " ";
