import React, { Component } from "react";
import { connect } from "react-redux";
import {
    VALOR_INICIAL,
    SIGNO_PORCENTAJE,
    CLASE_ADHERENCIA_FALTANTE,
    CLASE_ADHERENCIA_FALTANTE_ERROR,
    CLASE_ADHERENCIA_FALTANTE_ADVERTENCIA
} from "../../../../../utils/Constants";
class Adherencia extends Component {

    constructor(props){
        super(props);
        this.state = {
            cantidadActual:0,
        }
    }

    render() {
        let strMinimaAseguraglePct  = `(${this.props.porcentajeAdherencia} ${SIGNO_PORCENTAJE})`;
        let intMinimaAsegurable     = this.props.trabajadoresMinimo;
        let intTrabajadoresFaltante = this.props.trabajadoresTotal - this.props.trabajadoresAdherido;
        let intCantidadActual       = this.props.trabajadoresAdherido;
        let intTrabajadoresTotal    = this.props.trabajadoresTotal;
        let strClaseFaltante        = CLASE_ADHERENCIA_FALTANTE;

        if (Number(intTrabajadoresFaltante) < VALOR_INICIAL) {
            strClaseFaltante = `${strClaseFaltante} ${CLASE_ADHERENCIA_FALTANTE_ERROR}`;
        }
        else{
            if (Number(intCantidadActual) < Number(intMinimaAsegurable)) {
                strClaseFaltante = `${strClaseFaltante} ${CLASE_ADHERENCIA_FALTANTE_ADVERTENCIA}`;
            }
        }
        
        return (
            <section className="adherencia-contenedor">
                <section className="adherencia-item">
                    <h4>Número de trabajadores</h4>
                    <span>{intTrabajadoresTotal} Trabajadores</span>
                </section>
                <section className="adherencia-item">
                    <h4>Adherencia Mínima asegurable {strMinimaAseguraglePct}</h4>
                    <span>{intMinimaAsegurable} Trabajadores</span>
                </section>
                <section className="adherencia-item">
                    <h4>Trabajadores adheridos {intCantidadActual}</h4>
                    <span className={strClaseFaltante}>Faltan {intTrabajadoresFaltante} Trabajadores</span>
                </section>
            </section>         
        );
    }
}

const convertirEstadoApropiedad = state => ({
  store: state
});

export default connect(convertirEstadoApropiedad)(Adherencia);