import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const Cubierta = ({ objPatrocinador }) => {
  return (
    <header className="wizard-cover">
        <>
        <Container >
          <Row>
            <Col lg="7">
              <h1 className="text-left">
                Cotiza en 3 simples pasos
              </h1>
              <p className="text-left">{objPatrocinador.cubiertaMensaje}</p>
            </Col>
            <Col lg="5" className="wizard-btn-planes">
              { objPatrocinador.escogerPlanes && objPatrocinador.planes.map((objPlan, intIndice) =>{
                let strSeleccionado = objPlan.mostrar ? "planes-seleccionado" : "";
                let strUrl          = window.location.origin+window.location.pathname+objPlan.url;
                  return(
                    <button key={intIndice} id={objPlan.plan} className={`btn btn-planes ${strSeleccionado}`} onClick={ ()=> {window.location.href = strUrl}} >
                      <span>{objPlan.nombre}</span>
                    </button>
                  )
                })}
            </Col>
          </Row>
        </Container>
        </>
    </header>
  )
}
