import React, { Component } from "react";
import { connect } from "react-redux";
import Tramo from "../Tramo/Tramo";
import { guardarDistribucionTramosPorCotizacion } from "../../../../../actions/QuoteActions";
import { Tramo1, Tramo2, Tramo3 } from "../../../../../utils/IconosSvg";
import Swal from 'sweetalert2';
import {
  ALERTA_CLASE,
  SIGNO_NUMERAL,
  TRAMO_SIN_CARGA_ID,
  TRAMO_SIN_CARGA_TITULO,
  TRAMO_UNA_CARGA_ID,
  TRAMO_UNA_CARGA_TITULO,
  TRAMO_DOS_CARGA_ID,
  TRAMO_DOS_CARGA_TITULO,
  NUMERO_VACIO,
  VALOR_CERO,
  TRAMO_ERROR_TRABAJADORES_MAXIMO,
  VERDADERO,
  FALSO
} from "../../../../../utils/Constants";

class DetalleCarga extends Component {

  constructor(props){
    super(props);
    this.agregarTrabajadorPorTramo  = this.agregarTrabajadorPorTramo.bind(this);
    this.eliminarTrabajadorPorTramo = this.eliminarTrabajadorPorTramo.bind(this);
    this.configurarCargaPorTramo    = this.configurarCargaPorTramo.bind(this);
    this.state = {
      tramosDistribucion:{
        sinCarga: NUMERO_VACIO,
        unaCarga: NUMERO_VACIO,
        dosOmasCarga: NUMERO_VACIO,
        total: NUMERO_VACIO
      },         
      tramos:[
        {
          id: TRAMO_SIN_CARGA_ID,
          titulo: TRAMO_SIN_CARGA_TITULO,
          icono: <Tramo1/>,
          cantidad: NUMERO_VACIO
        },
        {
          id: TRAMO_UNA_CARGA_ID,
          titulo: TRAMO_UNA_CARGA_TITULO,
          icono: <Tramo2/>,
          cantidad: NUMERO_VACIO
        },
        {
          id: TRAMO_DOS_CARGA_ID,
          titulo: TRAMO_DOS_CARGA_TITULO,
          icono: <Tramo3/>,
          cantidad: NUMERO_VACIO
        }
      ]
    }
  }

  async componentDidMount(){
    
    let { tramosDistribucion } = this.props.store.reducer;
    let lstTramo               = this.state.tramos;

    if (tramosDistribucion !== null ) {
      
      lstTramo.forEach( (objTramo) => { 
        switch (objTramo.id) {
            case TRAMO_UNA_CARGA_ID:
                objTramo.cantidad = tramosDistribucion.unaCarga;
                break;

            case TRAMO_DOS_CARGA_ID:
                objTramo.cantidad = tramosDistribucion.dosOmasCarga;
                break;
        
            default:
                objTramo.cantidad = tramosDistribucion.sinCarga;
                break;
        }
      })

      this.setState({ tramosDistribucion, tramos: lstTramo });
    }
  }

  actualizarDistribucionCarga (blnRecalcular) {

    let { tramosDistribucion, tramos } = this.state;
    let intCantidadTotal               = NUMERO_VACIO;

    tramos.forEach( (objTramo) => { 

        switch (objTramo.id) {
            case TRAMO_UNA_CARGA_ID:
                tramosDistribucion.unaCarga = objTramo.cantidad;
                break;

            case TRAMO_DOS_CARGA_ID:
                tramosDistribucion.dosOmasCarga = objTramo.cantidad;
                break;
        
            default:
                tramosDistribucion.sinCarga = objTramo.cantidad;
                break;
        }
        intCantidadTotal += objTramo.cantidad
    })
    this.props.guardarDistribucionTramosPorCotizacion(tramosDistribucion);

    if (blnRecalcular) {
        this.props.tarjetasCalcular();
    }

    this.setState({ tramosDistribucion },()=>{this.props.actualizarDistribucionCarga(intCantidadTotal)});  
  }

  agregarTrabajadorPorTramo(objTramoSeleccionado, blnSeleccionCobertura){

    let lstTramo               = this.state.tramos;
    const intTrabajadoresTotal = this.props.trabajadoresTotal;
    const intIndice            = this.state.tramos.indexOf(objTramoSeleccionado);
    let intTotal               = NUMERO_VACIO;

    lstTramo.forEach( (objTramo) => { intTotal += objTramo.cantidad})
    intTotal++;

    if( Number(intTotal) <= Number(intTrabajadoresTotal) ){
        objTramoSeleccionado.cantidad ++;
    } 

    lstTramo[intIndice] = objTramoSeleccionado;
    this.setState({ tramos: lstTramo},()=>{this.actualizarDistribucionCarga (blnSeleccionCobertura)});   
  }

  eliminarTrabajadorPorTramo(objTramoSeleccionado, blnSeleccionCobertura){

    let lstTramo    = this.state.tramos;
    const intIndice = this.state.tramos.indexOf(objTramoSeleccionado);

    if(objTramoSeleccionado.cantidad > NUMERO_VACIO){
        objTramoSeleccionado.cantidad--;
    }

    lstTramo[intIndice] = objTramoSeleccionado;
    this.setState({ tramos: lstTramo},()=>{this.actualizarDistribucionCarga (blnSeleccionCobertura)}); 
  }

  configurarCargaPorTramo(objEvento, objTramoSeleccionado, blnSeleccionCobertura){

    let lstTramo               = this.state.tramos;
    const intTrabajadoresTotal = this.props.trabajadoresTotal;
    const intIndice            = this.state.tramos.indexOf(objTramoSeleccionado);
    let intTotal               = NUMERO_VACIO;

    lstTramo.forEach( (objTramo, intObjIndice) => { 
        intTotal = intIndice === intObjIndice ?  Number(objEvento.target.value) + intTotal : objTramo.cantidad + intTotal;
    })

    if( Number(intTotal) > Number(intTrabajadoresTotal) && 
        Number(intTotal-intTrabajadoresTotal) > VALOR_CERO){
        Swal.fire({
            title: TRAMO_ERROR_TRABAJADORES_MAXIMO.replace(SIGNO_NUMERAL,intTrabajadoresTotal),
            customClass: {
              title: ALERTA_CLASE,
              confirmButton: ALERTA_CLASE,
              popup: ALERTA_CLASE
            }
        })
    }
    else{
        let blnRecalcular             = Number(intIndice) === Number(intTrabajadoresTotal) && blnSeleccionCobertura ? VERDADERO:FALSO;
        objTramoSeleccionado.cantidad = Number(objEvento.target.value);
        lstTramo[intIndice]           = objTramoSeleccionado;
        this.setState({ tramos: lstTramo},()=>{this.actualizarDistribucionCarga (blnRecalcular)});
    }
  }

  render() {
    let lstTramo = this.state.tramos;

    return (
      <section className="tramo-contenedor">
          <p className="tramo-parrafo">Distribuye la cantidad de cargas que tenga tu empresa</p>
          <div>
              {lstTramo.map( (objTramo, intIndice) =>
                  <Tramo 
                      key={intIndice}
                      objTramo={objTramo} 
                      agregarTrabajadorPorTramo={this.agregarTrabajadorPorTramo} 
                      quitarTrabajadorPorTramo={this.eliminarTrabajadorPorTramo} 
                      cambiarCargaPorTramo={this.configurarCargaPorTramo} 
                      blnSeleccionCobertura={FALSO} />
              )}
          </div>
      </section>
    );
  }
}

const convertirEstadoApropiedad = state => ({
  store: state
});


export default connect(convertirEstadoApropiedad, {guardarDistribucionTramosPorCotizacion})(DetalleCarga);