import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col} from "react-bootstrap";
import { Tarjeta } from "../Tarjeta/Tarjeta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FORMATO_CODIGO_ESPANOL,
  FORMATO_ESTILO_MONEDA,
  FORMATO_PESO_CHILENO,
  DECIMALES_UF,
  VERDADERO,
  TAMANO_FILA_TARJETA,
  MAX_FILA_TARJETA
} from "../../../../utils/Constants";

class Simulador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tarjeta: null
    };
  }

  procesarPasoSimulador(objEvento) {
    objEvento.preventDefault();
    this.props.mostrarCargando(VERDADERO);
    this.props.nextPage();
  }

  render(){
    let { lstTarjetas, lstCoberturas, valoresUF, objPatrocinador} = this.props;
    let intTamanoFila     = lstTarjetas.length > MAX_FILA_TARJETA ? Number(MAX_FILA_TARJETA * TAMANO_FILA_TARJETA) : Number(lstTarjetas.length * TAMANO_FILA_TARJETA);
    let strClassNameNota = `col-xl-${intTamanoFila} col-lg-${intTamanoFila}`;

    const objClpFormato = new Intl.NumberFormat(FORMATO_CODIGO_ESPANOL, {
      style: FORMATO_ESTILO_MONEDA,
      currency: FORMATO_PESO_CHILENO,
      minimumFractionDigits: DECIMALES_UF,
      maximumFractionDigits: DECIMALES_UF,
    });
    return (
      <Fragment>
        <Row>
          { lstTarjetas.map((objTarjeta, intIndice) => (
              <Col xs={12} sm={12} md={6} lg={4} xl={4} key={`${intIndice}`}>
                <Tarjeta 
                  key={`${intIndice}-${objTarjeta.id}`}
                  objTarjeta={objTarjeta} 
                  lstCoberturas={lstCoberturas} 
                  blnSimulador={VERDADERO}
                  indice={intIndice}
                  seleccionarCoberturaLista={this.props.seleccionarCoberturaLista}
                  seleccionarCobertura={this.props.seleccionarCobertura}
                  objPatrocinador={objPatrocinador} />
              </Col>
            ))
          }
          <p className={`tarjeta-nota col-md-12 col-sm-12 col-12 ${strClassNameNota}`}>* Precio en pesos referencial calculado en base al valor UF = <strong>{objClpFormato.format(valoresUF.valorUf)}</strong> del día <strong>{valoresUF.fechaUf}</strong></p>
        </Row>
        <footer className="wizard-buttons text-center">
          <button type="submit" className="btn btn-primary" onClick={(objEvento) => {this.procesarPasoSimulador(objEvento);}} >
            Cotizar Plan
            <FontAwesomeIcon icon="arrow-right" />
          </button>
        </footer>
      </Fragment>
    )
  }
}

const convertirEstadoApropiedad = state => ({
  store: state
});

export default connect(convertirEstadoApropiedad)(Simulador);