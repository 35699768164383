import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import TagManager from "react-gtm-module";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { version } from "../../../package.json";
import Simulador from "./Cotizador/Simulador/Simulador";
import SeleccionCobertura from "./Cotizador/SeleccionCobertura/SeleccionCobertura";
import InformacionPersonal from "./Cotizador/InformacionPersonal/InformacionPersonal";
import DistribucionDotacion from "./Cotizador/DistribucionDotacion/DistribucionDotacion"
import {
  getRegiones,
  guardarTarjetasPorCotizacion,
  guardarCoberturasPorCotizacion,
  guardarCombinacionPlanesPorCotizacion,
  guardarUfPorFechaActual,
  guardarPatrocinadorPorCotizacion,
  nextPage,
  prevPage,
  getPlanInfo,
  getPlanSelected,
  guardarHistorialCotizacion,
  getPreCotizacion,
  getPersonalInformation,
  getCompanyInformation,
  guardarEjecutivos,
  reset
} from "../../actions/QuoteActions";
import {
  listarRegiones,
  buscarUfPorFecha,
  buscarCoberturasPorIdPatrocinador,
  buscarCombinacionPlanesPorIdPatrocinador,
  crearSimularCalculoPrimaFija,
  listarConPrimaFija,
  crearCopiaCierreCalculo,
  actualizarPrecotizacion,
  sendPrecotizacionInformation,
  buscarRepresentantesPorIdPatrocinadorIdClasificacion
} from "../../services/QuotationService";
import { 
  COTIZADOR_PASO_SIMULADOR,
  COTIZADOR_PASO_INFORMACION_PERSONAL,
  COTIZADOR_PASO_CARGAS,
  COTIZADOR_PASO_COBERTURAS,
  MES_OCTUBRE,
  DIA_DIEZ,
  ID_MONEDA_UF,
  VALOR_CERO,
  TRAMO_ID_UNA_CARGA,
  TRAMO_ID_DOS_CARGA,
  CANAL_VENTA_COTIZADOR,
  TIPO_INCORPORACION_DIGITAL,
  USUARIO_CREA_SISTEMA,
  TAMANO_UNO,
  DECIMALES_CLP,
  DECIMALES_UF,
  VALOR_INICIAL,
  LISTA_VACIA,
  PRIMERA_POSICION_LISTA,
  FORMATO_CODIGO_ESPANOL,
  FORMATO_ESTILO_MONEDA,
  FORMATO_PESO_CHILENO,
  ALERTA_POSICION_SUPERIOR_DERECHA,
  ALERTA_TIPO_ERROR,
  ALERTA_ERROR_CONEXION_SERVIDORES,
  COTIZADOR_PASOS,
  CODIGO_COBERTURA_VIDA,
  TRAMO_DOS_CARGA_MINIMO_CARGA,
  FALSO,
  NUMERO_VACIO,
  VERDADERO,
  TEXTO_CERO,
  COBERTURA_DEFECTO,
  POSICION_CERO,
  ORDENAMIENTO_VALORES_IGUALES,
  ORDENAMIENTO_VALOR_MAYOR,
  ORDENAMIENTO_VALOR_MENOR,
  FORMATO_FECHA_TEXTO,
  FORMATO_FECHA_NUMERO,
  PATROCINADOR_CLASIFICACION_EJECUTIVOS,
  PATROCINADOR_SIN_EJECUTIVO,
} from "../../utils/Constants";
import { Cubierta } from "./Cotizador/Cubierta/Cubierta";
import { Anuncio } from "./Anuncio/Anuncio";
import { Historial } from "./Historial/Historial";
import { PasoContenedor } from "./Cotizador/Pasos/PasoContenedor";
import { Menu } from "./Menu/Menu";
import { ModalContacto } from "./Modal/Modal";

class Contenedor extends Component {

  constructor(props) {
    super(props);
    this.mostrarHistorial          = this.mostrarHistorial.bind(this);
    this.seleccionarCobertura      = this.seleccionarCobertura.bind(this);
    this.seleccionarCoberturaLista = this.seleccionarCoberturaLista.bind(this);
    this.tarjetasCalcular          = this.tarjetasCalcular.bind(this);
    this.cerrarCotizacion          = this.cerrarCotizacion.bind(this);
    this.limpiarDatosCotizador     = this.limpiarDatosCotizador.bind(this);
    this.state = {
      blnMostrarMenu: FALSO,
      btnNuevaCotizacion: FALSO,
      pasosCotizador: COTIZADOR_PASOS,
      valoresUF: {
        valorUf: NUMERO_VACIO, fechaUf: null
      },
      lstTarjetas: [
        {
          id: NUMERO_VACIO,
          nombre: null,
          coberturas:null,
          urlArchivo: null,
          primaTitular: NUMERO_VACIO, 
          primaCarga1: NUMERO_VACIO, 
          primaCarga2: NUMERO_VACIO, 
          primaBrutaMensual:NUMERO_VACIO,
          primaBrutaMensualCLP:NUMERO_VACIO,
          primaAfectaTotal:NUMERO_VACIO,
          primaAfectaTotalCLP:NUMERO_VACIO,
          primaTotalTitular: NUMERO_VACIO, 
          primaTotalCarga1: NUMERO_VACIO, 
          primaTotalCarga2: NUMERO_VACIO,
          lstCoberturas: { vida:[], dental:[], ampliado:[]}
        }
      ],
      lstCoberturas: [],
      planCotizado: {
        nombrePlanes: null,
        folios: null
      },
      cotizacionesHistorial:[],
      patrocinador: null
    };
    TagManager.dataLayer({
      dataLayer: {
        event: "traza",
        event_category: "Cotizacion",
        event_label: "Cotización paso 0 - Simulador",
      },
    });
  }

  async componentDidMount() {

    const { cotizacionesHistorial } = this.props.store.reducer;
    const { objPatrocinador }       = this.props;
    let objValorUf                  = await this.cargarValorUfPorFechaHoy();
    let lstEjecutivos               = await buscarRepresentantesPorIdPatrocinadorIdClasificacion(objPatrocinador.id, PATROCINADOR_CLASIFICACION_EJECUTIVOS);
    let lstRegiones                 = await listarRegiones();
    let objRespuesta                = await this.cargarTarjetasCoberturasPorCotizacion(objPatrocinador, objValorUf);

    lstEjecutivos.unshift(PATROCINADOR_SIN_EJECUTIVO);

    this.props.guardarTarjetasPorCotizacion(objRespuesta.lstTarjetas);
    this.props.guardarCoberturasPorCotizacion(objRespuesta.lstCoberturas);
    this.props.guardarUfPorFechaActual(objValorUf);
    this.props.guardarPatrocinadorPorCotizacion(objPatrocinador);
    this.props.guardarEjecutivos(lstEjecutivos);
    this.props.getRegiones(lstRegiones);
    this.props.mostrarCargando(FALSO);
    
    let lstCombinacionPlanes = await this.cargarCombinacionesPlanesPorCotizacion(objPatrocinador.id);
    
    if (cotizacionesHistorial !== null) {
      this.setState({ cotizacionesHistorial });
    }

    this.setState({ valoresUF: objValorUf,
                    lstTarjetas: objRespuesta.lstTarjetas, 
                    coberturas: objRespuesta.lstCoberturas,
                    combinacionPlanes: lstCombinacionPlanes,
                    patrocinador: objPatrocinador });

    this.props.guardarCombinacionPlanesPorCotizacion(lstCombinacionPlanes);
    this.props.getPlanInfo(await listarConPrimaFija(objPatrocinador.token));
    
    
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = FALSO;
    document.body.appendChild(script);
  }

  async cargarValorUfPorFechaHoy(){
    let objOpcionesFormato  = { year: FORMATO_FECHA_NUMERO, month: FORMATO_FECHA_TEXTO, day: FORMATO_FECHA_NUMERO};
    let datHoy              = new Date();
    let intDia              = datHoy.getDate() < DIA_DIEZ ? TEXTO_CERO.concat(datHoy.getDate()):datHoy.getDate();
    let intMesAuxiliar      = datHoy.getMonth() + TAMANO_UNO;
    let intMes              = intMesAuxiliar <= MES_OCTUBRE ? TEXTO_CERO.concat(intMesAuxiliar):intMesAuxiliar;
    let strFechaHoyPeticion = `${datHoy.getFullYear()}${intMes}${intDia}`;
    let strFechaHoyMostrar  = datHoy.toLocaleDateString(FORMATO_CODIGO_ESPANOL, objOpcionesFormato);

    let { objeto : {valor}} = await buscarUfPorFecha(ID_MONEDA_UF,strFechaHoyPeticion);
    let objValorUf          = { valorUf: parseFloat(valor),
                                fechaUf: strFechaHoyMostrar }

    return objValorUf;
  }

  async cargarTarjetasCoberturasPorCotizacion(objPatrocinador, objValorUf){    

    let objRespuesta = await buscarCoberturasPorIdPatrocinador(objPatrocinador.id);

    if (objRespuesta){

      const objPlan  = objRespuesta.objeto.planes.filter((objPlan) => objPlan.plan === objPatrocinador.plan).shift();
      let lstTarjetas = [...objPlan.tarjetas];

      objPlan.tarjetas.forEach((objTarjeta, intTarjetaIndice) => {

        objPatrocinador.cubiertaMensaje     = objPlan.cubierta;
        objPatrocinador.precioMensaje       = objPlan.precioMensaje;
        const { codigo }                    = objTarjeta;
        const lstGrupoCoberturas             = [...objPlan.grupoCoberturas];
        let objTarjetaSeleccionada          = {...objTarjeta};
        objTarjetaSeleccionada.lstCoberturas = []; 
        objTarjetaSeleccionada.coberturas   = []; 
        let lstCoberturas                    = [];
        let lstGrupos                        = lstGrupoCoberturas.map( (objCobertura) => objCobertura.grupo );

        lstGrupos.forEach((strGrupo) => {

          const objGrupo           = {...lstGrupoCoberturas.find((objCobertura) => objCobertura.grupo === strGrupo)};
          let lstCoberturaGrupos   = objGrupo.coberturas.filter((objCobertura) => {

            let objFiltro = objCobertura.tarjetas.filter((objTarjeta) => objTarjeta.codigo === codigo );
            return objFiltro.length ? objCobertura : null;

          }).filter(objElemento => objElemento);

          if (lstCoberturaGrupos.length) {

            let objRequerido            = objGrupo.coberturas.map((objCobertura) => objCobertura.tarjetas.find((objTarjeta) => objTarjeta.codigo === codigo && objTarjeta.requerido))
                                                             .filter(objElemento => objElemento);
            objGrupo.blnRequerido       = FALSO;
            objGrupo.blnSeleccionada    = FALSO;
            objGrupo.blnCheckboxMostrar = lstCoberturaGrupos.length === TAMANO_UNO ? VERDADERO : FALSO;
            objGrupo.blnlstCoberturas   = lstCoberturaGrupos.length === TAMANO_UNO ? FALSO : VERDADERO;
  
            if(objRequerido.length){
              objGrupo.blnRequerido       = VERDADERO;
              objGrupo.blnSeleccionada    = lstCoberturaGrupos.length === TAMANO_UNO ? VERDADERO : FALSO;
              objGrupo.blnCheckboxMostrar = FALSO;
            }
  
            if (objGrupo.blnSeleccionada || !objGrupo.blnLstCoberturas) {
              let objCoberturaFinal = { ...objGrupo, cobertura: {...lstCoberturaGrupos[POSICION_CERO]} };
              delete objCoberturaFinal.coberturas;
              delete objCoberturaFinal.cobertura.tarjetas;
              objTarjetaSeleccionada.coberturas.push(objCoberturaFinal);
            }
            else{
              let objCoberturaFinal = { ...objGrupo, cobertura: {...COBERTURA_DEFECTO} };
              delete objCoberturaFinal.coberturas;
              delete objCoberturaFinal.cobertura.tarjetas;
              objTarjetaSeleccionada.coberturas.push(objCoberturaFinal);
            }
  
            objGrupo.coberturas               = [...lstCoberturaGrupos];
            lstCoberturas[lstCoberturas.length] = {...objGrupo};
          }
        })

        objTarjetaSeleccionada.lstCoberturas = [...lstCoberturas];
        objTarjetaSeleccionada               = this.calcularPreciosPorTarjetaSimulador(objTarjetaSeleccionada, objValorUf);
        lstTarjetas[intTarjetaIndice]        = objTarjetaSeleccionada;
      });

      return { lstTarjetas: lstTarjetas, lstCoberturas: objPlan.grupoCoberturas }
    }
    else{
      return { lstTarjetas: [], lstCoberturas: null}
    }
  }

  async cargarCombinacionesPlanesPorCotizacion(intIdPatrocinador){
    
    let objRespuesta = await buscarCombinacionPlanesPorIdPatrocinador(intIdPatrocinador);
  
    if (objRespuesta !== undefined && objRespuesta.status !== null){
      let { objeto : {cotizaciones} } = objRespuesta;

      return cotizaciones;
    }

    return [];
  }

  calcularPreciosPorTarjetaSimulador(objTarjeta, objValorUf){

    const objFormatoClp = new Intl.NumberFormat(FORMATO_CODIGO_ESPANOL, {
      style: FORMATO_ESTILO_MONEDA,
      currency: FORMATO_PESO_CHILENO,
      minimumFractionDigits: DECIMALES_CLP,
      maximumFractionDigits: DECIMALES_CLP
    });

    const objFormatoUf = new Intl.NumberFormat(FORMATO_CODIGO_ESPANOL, {
      minimumFractionDigits: DECIMALES_UF,
      maximumFractionDigits: DECIMALES_UF
    });

    const { coberturas } = objTarjeta;

    let fltPrimaTotalTitular = Number(objTarjeta.primaTitular);
    let fltPrimaTotalCarga1  = Number(objTarjeta.primaCarga1);
    let fltPrimaTotalCarga2  = Number(objTarjeta.primaCarga2);

    coberturas.forEach(objCobertura => {
      if (objCobertura.blnSeleccionada) {
        if (objCobertura.grupo === CODIGO_COBERTURA_VIDA) {
          fltPrimaTotalTitular = fltPrimaTotalTitular + objCobertura.cobertura.primaTotal;
          fltPrimaTotalCarga1  = fltPrimaTotalCarga1  + objCobertura.cobertura.primaTotal;
          fltPrimaTotalCarga2  = fltPrimaTotalCarga2  + objCobertura.cobertura.primaTotal;
        }
        else{
          fltPrimaTotalTitular = fltPrimaTotalTitular + objCobertura.cobertura.primaTitular;
          fltPrimaTotalCarga1  = fltPrimaTotalCarga1  + objCobertura.cobertura.primaCarga1;
          fltPrimaTotalCarga2  = fltPrimaTotalCarga2  + objCobertura.cobertura.primaCarga2;
        }
      }
    });

    objTarjeta.primaTotalTitular = objFormatoUf.format(fltPrimaTotalTitular.toFixed(DECIMALES_UF));
    objTarjeta.primaTotalCarga1  = objFormatoUf.format(fltPrimaTotalCarga1.toFixed(DECIMALES_UF));
    objTarjeta.primaTotalCarga2  = objFormatoUf.format(fltPrimaTotalCarga2.toFixed(DECIMALES_UF));
    objTarjeta.primaBrutaMensual = objFormatoUf.format(VALOR_INICIAL);
    objTarjeta.primaAfectaTotal  = objFormatoUf.format(VALOR_INICIAL);

    objTarjeta.primaTotalTitularCLP = objFormatoClp.format((fltPrimaTotalTitular * objValorUf.valorUf).toFixed(DECIMALES_CLP));
    objTarjeta.primaTotalCarga1CLP  = objFormatoClp.format((fltPrimaTotalCarga1 * objValorUf.valorUf).toFixed(DECIMALES_CLP));
    objTarjeta.primaTotalCarga2CLP  = objFormatoClp.format((fltPrimaTotalCarga2 * objValorUf.valorUf).toFixed(DECIMALES_CLP));
    objTarjeta.primaBrutaMensualCLP = objFormatoClp.format((VALOR_INICIAL).toFixed(DECIMALES_CLP));
    objTarjeta.primaAfectaTotalCLP  = objFormatoClp.format((VALOR_INICIAL).toFixed(DECIMALES_CLP));
    
    return objTarjeta;
  }

  async seleccionarCobertura(intIdTarjeta, strGrupoCobertura, blnSimulador){

    let { lstTarjetas, valoresUF} = this.state;
    const objTarjeta             = lstTarjetas.find((objTarjeta) => objTarjeta.id === intIdTarjeta);
    const intTarjetaIndice       = lstTarjetas.findIndex((objTarjeta) => objTarjeta.id === intIdTarjeta);
    let objTarjetaSeleccionada   = {...objTarjeta };
    let lstCoberturas             = [...objTarjetaSeleccionada.coberturas];

    objTarjetaSeleccionada.coberturas.forEach(objCobertura =>{
      if (objCobertura.grupo === strGrupoCobertura) {
        objCobertura.blnSeleccionada = !objCobertura.blnSeleccionada;

        if (objCobertura.coberturasDependientes) {
          objCobertura.coberturasDependientes.forEach((strCoberturaDependiente)=>{

            lstCoberturas.filter((objCoberturaAux) => {

              if (objCoberturaAux.grupo === strCoberturaDependiente) {
                objCoberturaAux.independiente = !objCoberturaAux.independiente;
                if (!objCobertura.blnSeleccionada && objCoberturaAux.blnSeleccionada) {
                  objCoberturaAux.blnSeleccionada = !objCoberturaAux.blnSeleccionada;
                }
                if (objCoberturaAux.blnlstCoberturas) {
                  objCoberturaAux.cobertura = {...COBERTURA_DEFECTO};
                }
              }
              return objCoberturaAux;
            })
          })
        }
      }
    });

    if (blnSimulador) {
      objTarjetaSeleccionada = this.calcularPreciosPorTarjetaSimulador(objTarjetaSeleccionada, valoresUF);
    }
    else{
      objTarjetaSeleccionada = await this.calcularTarjeta(objTarjetaSeleccionada);
    }

    lstTarjetas                   = [...lstTarjetas];
    lstTarjetas[intTarjetaIndice] = objTarjetaSeleccionada;

    this.props.guardarTarjetasPorCotizacion(lstTarjetas);
    this.setState({lstTarjetas : lstTarjetas})
  }

  async seleccionarCoberturaLista(intIdTarjeta, objCoberturaSeleccionada, strGrupo, blnSimulador){

    const { lstTarjetas, valoresUF} = this.state;
    const objTarjeta               = lstTarjetas.find((objTarjeta) => objTarjeta.id === intIdTarjeta);
    const intTarjetaIndice         = lstTarjetas.findIndex((objTarjeta) => objTarjeta.id === intIdTarjeta);
    let objTarjetaSeleccionada     = {...objTarjeta };
    let lstCoberturas               = [...objTarjetaSeleccionada.coberturas];
    let lstAuxiliarTarjetas;

    objTarjetaSeleccionada.coberturas.forEach(objCobertura =>{

      if (objCobertura.grupo === strGrupo) {
        objCobertura.cobertura = {...objCoberturaSeleccionada};
        objCobertura.blnSeleccionada = objCoberturaSeleccionada.id > VALOR_CERO ? VERDADERO:FALSO;

        if (objCobertura.coberturasDependientes) {
          objCobertura.coberturasDependientes.forEach((strCoberturaDependiente)=>{

            lstCoberturas.filter((objCoberturaAux) => {

              if (objCoberturaAux.grupo === strCoberturaDependiente) {
                objCoberturaAux.independiente = !objCoberturaAux.independiente;
                if (!objCobertura.blnSeleccionada && objCoberturaAux.blnSeleccionada) {
                  objCoberturaAux.blnSeleccionada = !objCoberturaAux.blnSeleccionada;
                }
                if (objCoberturaAux.blnlstCoberturas) {
                  objCoberturaAux.cobertura = {...COBERTURA_DEFECTO};
                }
              }
              return objCoberturaAux;
            })
          })
        }

      }
    });

    if (blnSimulador) {
      objTarjetaSeleccionada = this.calcularPreciosPorTarjetaSimulador(objTarjetaSeleccionada, valoresUF);
    }
    else{
      objTarjetaSeleccionada =  await this.calcularTarjeta(objTarjetaSeleccionada);
    }

    lstAuxiliarTarjetas                   = [...lstTarjetas];
    lstAuxiliarTarjetas[intTarjetaIndice] = objTarjetaSeleccionada;
    
    this.props.guardarTarjetasPorCotizacion(lstTarjetas);
    this.setState({lstTarjetas : lstAuxiliarTarjetas})
  }

  async tarjetasCalcular(){

    let lstTarjetas = [...this.state.lstTarjetas];

    lstTarjetas = await Promise.all(lstTarjetas.map( async objTarjeta => {

      return this.calcularTarjeta(objTarjeta);
    }));

    this.setState({ lstTarjetas: [...lstTarjetas] });
    this.props.guardarTarjetasPorCotizacion(lstTarjetas);
  }

  async calcularTarjeta(objTarjeta){

    let lstCoberturas = [];

    lstCoberturas.push({ glosaCodigo: objTarjeta.codigo });

    objTarjeta.coberturas.forEach(objGrupo => {
      if (objGrupo.blnSeleccionada && !objGrupo.principal && objGrupo.grupo !== CODIGO_COBERTURA_VIDA) {
        lstCoberturas.push({ glosaCodigo: objGrupo.cobertura.codigo });
      }
    });

    let lstIdCotizaciones         = this.burcarIdsCotizacionesPorTarjeta(objTarjeta,lstCoberturas);
    let lstCotizaciones           = this.burcarCotizacionesPorTarjeta(lstIdCotizaciones);
    let lstSimulacionCotizaciones = await crearSimularCalculoPrimaFija(lstCotizaciones);
    let lstCalculoCotizaciones    = this.calcularTotalesPorCotizacion(lstSimulacionCotizaciones);
    let objTarjetaCalculada       = this.calcularPreciosPorTrajeta(lstCalculoCotizaciones, objTarjeta);

    return objTarjetaCalculada;
  }

  calcularPreciosPorTrajeta(lstCotizaciones, objTarjeta){

    const { valoresUF }       = this.state;
    let fltPrimaBrutaMensual  = lstCotizaciones.reduce((fltPrecio, objCotizacion) => fltPrecio + objCotizacion.valor + objCotizacion.iva, VALOR_INICIAL);
    let fltPrimaAfectaTotal   = lstCotizaciones.reduce((fltValor, objCotizacion) => fltValor + objCotizacion.valor, VALOR_INICIAL);
    let fltPrimaTotalTitular  = VALOR_INICIAL;
    let fltPrimaTotalCarga1   = VALOR_INICIAL;
    let fltPrimaTotalCarga2   = VALOR_INICIAL;

    lstCotizaciones.forEach(objCotizacion => {

      objCotizacion.tramos.forEach((objTramo, i) => {

        if (objTramo.nroTitular !== VALOR_CERO) {

          switch (objTramo.tramoAsegurable.idTramoAsegurable) {

            case TRAMO_ID_UNA_CARGA:
              fltPrimaTotalCarga1 += objTramo.primaAfectaIndividual;
              break;
      
            case TRAMO_ID_DOS_CARGA:
              fltPrimaTotalCarga2 += objTramo.primaAfectaIndividual;
              break;
          
            default: //TRAMO_ID_SIN_CARGA
              fltPrimaTotalTitular += objTramo.primaAfectaIndividual;
              break;
          }
        }
      })
    })

    const objFormatoClp = new Intl.NumberFormat(FORMATO_CODIGO_ESPANOL, {
      style: FORMATO_ESTILO_MONEDA,
      currency: FORMATO_PESO_CHILENO,
      minimumFractionDigits: DECIMALES_CLP,
      maximumFractionDigits: DECIMALES_CLP
    });

    const objFormatoUf = new Intl.NumberFormat(FORMATO_CODIGO_ESPANOL, {
      minimumFractionDigits: DECIMALES_UF,
      maximumFractionDigits: DECIMALES_UF
    });

    objTarjeta.primaTotalTitular = objFormatoUf.format(fltPrimaTotalTitular);
    objTarjeta.primaTotalCarga1  = objFormatoUf.format(fltPrimaTotalCarga1);
    objTarjeta.primaTotalCarga2  = objFormatoUf.format(fltPrimaTotalCarga2);
    objTarjeta.primaBrutaMensual = objFormatoUf.format(fltPrimaBrutaMensual);
    objTarjeta.primaAfectaTotal  = objFormatoUf.format(fltPrimaAfectaTotal);

    objTarjeta.primaTotalTitularCLP = objFormatoClp.format((fltPrimaTotalTitular * valoresUF.valorUf).toFixed(DECIMALES_CLP));
    objTarjeta.primaTotalCarga1CLP  = objFormatoClp.format((fltPrimaTotalCarga1 * valoresUF.valorUf).toFixed(DECIMALES_CLP));
    objTarjeta.primaTotalCarga2CLP  = objFormatoClp.format((fltPrimaTotalCarga2 * valoresUF.valorUf).toFixed(DECIMALES_CLP));
    objTarjeta.primaBrutaMensualCLP = objFormatoClp.format((fltPrimaBrutaMensual * valoresUF.valorUf).toFixed(DECIMALES_CLP));
    objTarjeta.primaAfectaTotalCLP  = objFormatoClp.format((fltPrimaAfectaTotal * valoresUF.valorUf).toFixed(DECIMALES_CLP));

    return objTarjeta;
  }

  calcularTotalesPorCotizacion(lstSimulacionCotizaciones){

    let { informacionEmpresa } = this.props.store.reducer;
    
    return lstSimulacionCotizaciones.map((objCotizacion) => {

      objCotizacion.selected           = FALSO;
      objCotizacion.recommended        = FALSO;
      objCotizacion.rutEmpresa         = informacionEmpresa.rutEmpresaFull;
      objCotizacion.idRegion           = informacionEmpresa.idRegion;
      objCotizacion.razonSocialEmpresa = informacionEmpresa.razonSocial;
      objCotizacion.tramos             = [];
      let lstTramos                    = null;

      objCotizacion.planCotizado.forEach(objPlanCotizado => {

        lstTramos = (lstTramos === null) ? objPlanCotizado.prima : lstTramos.concat(objPlanCotizado.prima);
      });

      let intValor = VALOR_INICIAL;
      let intIva   = VALOR_INICIAL;

      for (let intPosicion = PRIMERA_POSICION_LISTA; intPosicion < lstTramos.length; intPosicion++) {

        let objTramo = Object.assign({}, lstTramos[intPosicion]);
        intValor    += objTramo.primaAfectaTotal;
        intIva      += objTramo.ivaTotal;

        let intIndex = objCotizacion.tramos.findIndex(a => a.tramoAsegurable.idTramoAsegurable === objTramo.tramoAsegurable.idTramoAsegurable);

        if (intIndex === -1) {
          objCotizacion.tramos.push(objTramo);
        } 
        else {
          objCotizacion.tramos[intIndex].ivaIndividual          = objCotizacion.tramos[intIndex].ivaIndividual + objTramo.ivaIndividual;
          objCotizacion.tramos[intIndex].ivaTotal              += objTramo.ivaTotal;
          objCotizacion.tramos[intIndex].primaAfectaIndividual += objTramo.primaAfectaIndividual;
          objCotizacion.tramos[intIndex].primaAfectaTotal      += objTramo.primaAfectaTotal;
          objCotizacion.tramos[intIndex].primaBrutaIndividual  += objTramo.primaBrutaIndividual;
          objCotizacion.tramos[intIndex].primaBrutaTotal       += objTramo.primaBrutaTotal;
        }
      }

      objCotizacion.valor = intValor;
      objCotizacion.iva   = intIva;

      return objCotizacion;
    });
  }

  asignarCargasPorPrima(objPrima){

    let { tramosDistribucion } = this.props.store.reducer;

    switch (objPrima.tramoAsegurable.idTramoAsegurable) {
      case TRAMO_ID_UNA_CARGA:
        objPrima.nroTitular = tramosDistribucion.unaCarga;
        objPrima.nroCarga   = tramosDistribucion.unaCarga;
        break;

      case TRAMO_ID_DOS_CARGA:
        objPrima.nroTitular = tramosDistribucion.dosOmasCarga;
        objPrima.nroCarga   = Number(tramosDistribucion.dosOmasCarga * TRAMO_DOS_CARGA_MINIMO_CARGA);
        break;
    
      default: //TRAMO_ID_SIN_CARGA
        objPrima.nroTitular = tramosDistribucion.sinCarga;
        objPrima.nroCarga   = VALOR_CERO;
        break;
    }

    return objPrima;
  }

  burcarIdsCotizacionesPorTarjeta(objTarjeta, lstCoberturas){

    let { combinacionPlanes } = this.props.store.reducer;
    let lstIdCotizaciones     = [];
    let lstCombinacionPlanes  = [...combinacionPlanes];
    let objGrupoVida          = objTarjeta.coberturas.find((objCobertura) => objCobertura.grupo === CODIGO_COBERTURA_VIDA);

    lstCombinacionPlanes.forEach( (objCombinacion) => {

      if (Number(objCombinacion.coberturas.length) === Number(lstCoberturas.length)) {
        
        let strCoberturasSeleccionada = JSON.stringify(lstCoberturas.sort((objCobertura1, objCobertura2)=> {
          return (objCobertura1.glosaCodigo === objCobertura2.glosaCodigo) ?  
            ORDENAMIENTO_VALORES_IGUALES : 
            (objCobertura1.glosaCodigo > objCobertura2.glosaCodigo) ? 
              ORDENAMIENTO_VALOR_MAYOR : 
              ORDENAMIENTO_VALOR_MENOR;
        }));

        let strCombinacion = JSON.stringify(objCombinacion.coberturas.sort((objCobertura1, objCobertura2) => { 
          return (objCobertura1.glosaCodigo === objCobertura2.glosaCodigo) ? 
            ORDENAMIENTO_VALORES_IGUALES : 
            (objCobertura1.glosaCodigo > objCobertura2.glosaCodigo) ? 
              ORDENAMIENTO_VALOR_MAYOR : 
              ORDENAMIENTO_VALOR_MENOR 
        }));

        if (strCoberturasSeleccionada === strCombinacion) {
          lstIdCotizaciones.push(objCombinacion.idCotizacion);
        }
      }

      if (objGrupoVida && objGrupoVida.blnSeleccionada && 
          objCombinacion.coberturas.length === TAMANO_UNO && 
          objCombinacion.coberturas[POSICION_CERO].glosaCodigo === objGrupoVida.cobertura.codigo ) {
        
        lstIdCotizaciones.push(objCombinacion.idCotizacion);
      }
    });

    return lstIdCotizaciones;
  }

  burcarCotizacionesPorTarjeta(lstIdCotizaciones){
    
    let { planInfo }  = this.props.store.reducer;
    let lstCotizaciones = [];

    planInfo.objeto.forEach( objElemento => {
        
      objElemento.forEach( objPlan => {
        
        let lstIdsCotizacionesAuxiliar = [];
        
        objPlan.forEach(objSubplan => {

          lstIdsCotizacionesAuxiliar.push(objSubplan.idCotizacion);
        });

        let strCotizacionesIdAux = JSON.stringify(lstIdsCotizacionesAuxiliar.sort());
        let strCotizacionesId    = JSON.stringify(lstIdCotizaciones.sort());

        if (strCotizacionesIdAux === strCotizacionesId) {

          objPlan.forEach(objSubplan => {

            objSubplan.planCotizado.map(objPlanCotizado => {
            
              return objPlanCotizado.prima.map(objPrima => {
                
                return this.asignarCargasPorPrima(objPrima);
              });
            });
            lstCotizaciones.push(objSubplan);
          })
        }
      });
    });
    return lstCotizaciones;
  }

  async cerrarCotizacion(intIdTarjeta){

    let { informacionEmpresa, informacionPersonal, preCotizacion } = this.props.store.reducer;

    const objPersona       = { persona: {...informacionPersonal } };
    const objEmpresa       = { empresa: {...informacionEmpresa } };
    const objPrecotizacion = { preCotizacion: {...preCotizacion} };

    const objPromesaPersona = new Promise( async (procesarPromesa) => {
      if (informacionPersonal.idPersona !== null) {
        
        let objInfoPersonalGuardado = this.props.store.reducer.informacionPersonal;
        let strRut                  = objInfoPersonalGuardado.rut + "-" + objInfoPersonalGuardado.digitoVerificador;
        let objInformacionPersonal  = {
          ...objInfoPersonalGuardado,
          rut: strRut
        };
        
        if (JSON.stringify(informacionPersonal) !== JSON.stringify(objInformacionPersonal)) {
          actualizarPrecotizacion(objPersona);
        }
        procesarPromesa(informacionPersonal.idPersona);
      }
      else {
        let intIdPersona = await sendPrecotizacionInformation(objPersona);
        procesarPromesa(intIdPersona);
      }
    });

    objPromesaPersona.then( (intIdPersona) => {
      
      informacionPersonal.idPersona = intIdPersona;
      objEmpresa.empresa.idPersona = intIdPersona;

      this.setState({informacionPersonal})
      this.props.getPersonalInformation(informacionPersonal);

      const objPromesaEmpresa = new Promise( async (procesarPromesa) => {
        
        if (informacionEmpresa.idEmpresa !== null) {
          let objInfoEmpresaGuardado = this.props.store.reducer.informacionEmpresa;
          let strRutEmpresa          = objInfoEmpresaGuardado.rutEmpresa + "-" + objInfoEmpresaGuardado.digitoVerificador;
          let objInformacionEmpresa  = {
            ...objInfoEmpresaGuardado,
            rutEmpresa: strRutEmpresa
          };
          
          if (JSON.stringify(informacionEmpresa) !== JSON.stringify(objInformacionEmpresa)) {
            actualizarPrecotizacion(objEmpresa);
          }
          procesarPromesa(informacionEmpresa.idEmpresa);
        } 
        else {
          let intIdEmpresa = await sendPrecotizacionInformation(objEmpresa);
          procesarPromesa(intIdEmpresa);
        }
      });

      objPromesaEmpresa.then(async (intIdEmpresa) => {
        
        informacionEmpresa.idEmpresa = intIdEmpresa;
        informacionEmpresa.idPersona = intIdPersona;
        objPrecotizacion.preCotizacion.idEmpresa = intIdEmpresa;

        this.setState({informacionEmpresa})
        this.props.getCompanyInformation(informacionEmpresa);

        const objPromesaPrecotizacion = new Promise( async (procesarPromesa) => {

          if (objPrecotizacion.preCotizacion.idPrecotizacion) {
  
            actualizarPrecotizacion(objPrecotizacion);
            procesarPromesa(objPrecotizacion.preCotizacion.idPrecotizacion);
          }
          else{
            let intIdPrecotizacion = await sendPrecotizacionInformation(objPrecotizacion);
            procesarPromesa(intIdPrecotizacion);
          }
        });

        objPromesaPrecotizacion.then((intIdPrecotizacion) => {

          preCotizacion.idPrecotizacion = intIdPrecotizacion;
          preCotizacion.idEmpresa = intIdEmpresa;

          this.props.getPreCotizacion(preCotizacion);
          this.completarCreacionCotizacion(intIdTarjeta);
        });
      });
    });
  }

 async completarCreacionCotizacion(intIdTarjeta){

  let { poblacion, informacionEmpresa, informacionPersonal, preCotizacion, patrocinador } = this.props.store.reducer;
  let { lstTarjetas } = this.state;
  const objTarjeta    = lstTarjetas.find((objTarjeta) => objTarjeta.id === intIdTarjeta);
  let lstCoberturas   = [];

    lstCoberturas.push({ glosaCodigo: objTarjeta.codigo});

    objTarjeta.coberturas.forEach(objGrupo => {
      if (objGrupo.blnSeleccionada && !objGrupo.principal  && objGrupo.grupo !== CODIGO_COBERTURA_VIDA) {
        lstCoberturas.push({ glosaCodigo: objGrupo.cobertura.codigo });
      }
    });

    let lstIdCotizaciones         = this.burcarIdsCotizacionesPorTarjeta(objTarjeta,lstCoberturas);
    let lstCotizaciones           = this.burcarCotizacionesPorTarjeta(lstIdCotizaciones);
    let lstSimulacionCotizaciones = await crearSimularCalculoPrimaFija(lstCotizaciones);
    let lstCalculoCotizaciones    = this.calcularTotalesPorCotizacion(lstSimulacionCotizaciones);
    let intIdEjecutivo            = informacionEmpresa.idEjecutivo;

    lstCalculoCotizaciones.map(objCotizacion => {
      objCotizacion.fechaInicioVigencia        = poblacion.fechaVigencia;
      objCotizacion.porcentajeMinimaAdherencia = poblacion.porcentajeAdherencia;
      return objCotizacion;
    })

    informacionPersonal.idRegion = informacionEmpresa.idRegion;

    const objCierre = {
      patrocinador: {
        idPatrocinador : patrocinador.id,
        idEjecutivo : intIdEjecutivo,
        idRepresentanteClasificacion: informacionEmpresa.idRepresentanteClasificacion
      },
      preCotizacion: {
        preCotizacion: preCotizacion,
        empresa: informacionEmpresa,
        persona: informacionPersonal
      },
      cotizacion: lstCalculoCotizaciones,
      idCanalVenta: CANAL_VENTA_COTIZADOR,
      idTipoIncorporacion: TIPO_INCORPORACION_DIGITAL,
      idUsuarioCrea: USUARIO_CREA_SISTEMA,

    };

    let objPlanCotizado = {
      cotizaciones: await crearCopiaCierreCalculo(objCierre),
      tarjeta: objTarjeta
    }

    if (objPlanCotizado.cotizaciones !== null) {
      
      let { cotizacionesHistorial } = this.state;
      let strFolio      = objPlanCotizado.cotizaciones.reduce((strPlanNombre, cotizacion) => strPlanNombre ? `${strPlanNombre} + ${cotizacion.codigoCotizacion}` : `${cotizacion.codigoCotizacion}`,'');
      let strPlanNombre = objPlanCotizado.cotizaciones.reduce((strPlanNombre, cotizacion) => strPlanNombre ? `${strPlanNombre} + ${cotizacion.descripcion}` : `${cotizacion.descripcion}`,'');
      
      objPlanCotizado.planNombre = strPlanNombre;
      objPlanCotizado.folio      = strFolio;

      cotizacionesHistorial.push({ planNombre: strPlanNombre, folio: strFolio });
      this.setState({ planCotizado: objPlanCotizado, cotizacionesHistorial, btnNuevaCotizacion: VERDADERO, blnMostrarMenu: VERDADERO })
      this.props.getPlanSelected(objPlanCotizado);
      this.props.guardarHistorialCotizacion(cotizacionesHistorial);
      TagManager.dataLayer({
        dataLayer: {
          event: "traza",
          event_category: "Cotizacion",
          event_label: `Cotización paso 4 - Cotización Realizada (${strFolio})`,
        },
      })
    } 
    else {
      Swal.mixin({
        toast: VERDADERO,
        position: ALERTA_POSICION_SUPERIOR_DERECHA,
        showConfirmButton: FALSO,
        timer: 3000
      }).fire({
        type: ALERTA_TIPO_ERROR,
        title: ALERTA_ERROR_CONEXION_SERVIDORES
      });
    }
    this.props.mostrarCargando(FALSO);
  }

  mostrarHistorial(blnMostrarMenu){

    if(blnMostrarMenu){
      this.setState({blnMostrarMenu: FALSO})
    }else{
      this.setState({blnMostrarMenu: VERDADERO})
    }    
  }

  limpiarDatosCotizador(){
    this.setState({blnMostrarMenu: FALSO, cotizacionesHistorial : []})
    this.props.reset();
  }

  render() {
    let { lstTarjetas, lstCoberturas, valoresUF, pasosCotizador, cotizacionesHistorial } = this.state;
    const objPatrocinador         = this.props.objPatrocinador;
    const intPagina               = this.props.store.reducer.page;
    let blnCotizacionesHistorial  = cotizacionesHistorial.length > LISTA_VACIA ? VERDADERO:FALSO;

    return (
      <Fragment>
        <Menu objPatrocinador={objPatrocinador} />
        <Anuncio objPatrocinador={objPatrocinador} />
        { blnCotizacionesHistorial && (
          <Historial objEstado={this.state} mostrarHistorial={this.mostrarHistorial} />
        )}
        <section id="quotation" className="wizard">
          <Cubierta objPatrocinador={objPatrocinador} />
          <section className="wizard-body">
            <PasoContenedor pasosCotizador={pasosCotizador} intPagina={intPagina} />
            <Container fluid className="wizard-container">
              {{ 
                  [COTIZADOR_PASO_SIMULADOR]: <Simulador 
                                                lstTarjetas={lstTarjetas} 
                                                lstCoberturas={lstCoberturas} 
                                                valoresUF={valoresUF}
                                                seleccionarCoberturaLista={this.seleccionarCoberturaLista}
                                                seleccionarCobertura={this.seleccionarCobertura}
                                                procesarPasoSimulador={this.procesarPasoSimulador} 
                                                nextPage={this.props.nextPage}
                                                mostrarCargando={this.props.mostrarCargando}
                                                objPatrocinador={objPatrocinador}
                                              />,
                  [COTIZADOR_PASO_INFORMACION_PERSONAL]: <InformacionPersonal 
                                                  objPatrocinador={objPatrocinador} 
                                                  nextPage={this.props.nextPage}
                                                  prevPage={this.props.prevPage}
                                                  mostrarCargando={this.props.mostrarCargando}
                                                />,
                  [COTIZADOR_PASO_CARGAS] : <DistribucionDotacion 
                                              nextPage={this.props.nextPage} 
                                              prevPage={this.props.prevPage}
                                              mostrarCargando={this.props.mostrarCargando}
                                              tarjetasCalcular={this.tarjetasCalcular} 
                                            />,
                  [COTIZADOR_PASO_COBERTURAS] : <SeleccionCobertura 
                                                  lstTarjetas={lstTarjetas} 
                                                  lstCoberturas={lstCoberturas} 
                                                  valoresUF={valoresUF}
                                                  seleccionarCoberturaLista={this.seleccionarCoberturaLista}
                                                  seleccionarCobertura={this.seleccionarCobertura}
                                                  prevPage={this.props.prevPage}
                                                  mostrarCargando={this.props.mostrarCargando}
                                                  tarjetasCalcular={this.tarjetasCalcular}
                                                  cerrarCotizacion={this.cerrarCotizacion}
                                                  btnNuevaCotizacion={this.state.btnNuevaCotizacion}
                                                  limpiarDatosCotizador={this.limpiarDatosCotizador}
                                                  objPatrocinador={objPatrocinador}
                                                />
                }[intPagina]
              }
            </Container>
          </section>
        </section>
        <div className="versionWeb">{version}</div>
        <ModalContacto objPatrocinador={objPatrocinador} modal={this.state.modal} />
      </Fragment>
    )
  }
}

const convertirEstadoApropiedad = state => ({
  store: state
});

export default connect(convertirEstadoApropiedad, {
  getRegiones,
  nextPage,
  prevPage,
  guardarTarjetasPorCotizacion,
  guardarUfPorFechaActual,
  guardarCoberturasPorCotizacion,
  guardarCombinacionPlanesPorCotizacion,
  getPlanInfo,
  getPlanSelected,
  guardarHistorialCotizacion,
  reset,
  guardarPatrocinadorPorCotizacion,
  getPreCotizacion,
  getPersonalInformation,
  getCompanyInformation,
  guardarEjecutivos
})(Contenedor);