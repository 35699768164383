import axios from "axios";
import {ENDPOINT_LISTAR_PLANTILLA_POR_PATROCINADOR} from "../utils/Constants";

class ServicesFactory {
  setAuthorization(token) {
    axios.defaults.headers.common["Authorization"] = token;
  }

  async get(path, prex_uri = true) {
    try {
      if (path.indexOf(ENDPOINT_LISTAR_PLANTILLA_POR_PATROCINADOR) > 0 ) {
        delete axios.defaults.headers.common["Accept"];
      } 
      return await axios.get(
        prex_uri ? process.env.REACT_APP_API_URL_COTIZADOR + path : path
      );
    } catch (error) {
      this.handleError(error);
    }
  }

  async post(path, body, prex_uri = true) {
    const res = await axios.post(
      prex_uri ? process.env.REACT_APP_API_URL_COTIZADOR + path : path,
      body
    );
    return await res;
  }

  handleError(error = ErrorEvent) {
    if (error instanceof ErrorEvent) {
      console.error(
        "Ha ocurrido un error de red o un error en el cliente",
        error
      );
    } else {
      console.error("Error en servidor", error);
    }
    return console.error("Ha ocurrido un error");
  }
}

export default ServicesFactory;
