import React from "react"

export const Cargando = ({strLogoBlanco}) => {

	return (
		<div className="loading">
			<img className="lds-image" src={require(`./../assets/images/${strLogoBlanco}`)} alt="logo" />
			<div className="lds-roller">
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	)
}