import {
  GET_PERSONAL_INFORMATION,
  GET_COMPANY_INFORMATION,
  GET_QUOTE,
  GET_PLAN_INFO,
  GET_TRAMO_ASEGURADO,
  GET_PRECOTIZACION,
  GET_REGIONES,
  NEXT_PAGE,
  PREV_PAGE,
  RESET,
  OPEN_MODAL,
  GET_PLAN_SELECTED,
  GET_ID_PRECOTIZACION,
  GET_SALUD_DIGITAL,
  GET_PERSONAL_ID,
  GET_COMPANY_ID,
  OBTENER_PERSONA,
  OBTENER_EMPRESA,
  OBTENER_PRECOTIZACION,
  OBTENER_TRABAJADORES,
  OBTENER_PATROCINADOR,
  OBTENER_TARJETAS,
  OBTENER_COBERTURAS,
  OBTENER_DISTRIBUCION_TRAMOS,
  OBTENER_POBLACION,
  OBTENER_COMBINACION_PLANES,
  OBTENER_UF,
  OBTENER_HISTORIAL_COTIZACIONES,
  OBTENER_EJECUTIVO
} from "./types";

export const getPersonalInformation = payload => {
  return {
    type: GET_PERSONAL_INFORMATION,
    payload: payload
  };
};

export const getCompanyInformation = payload => {
  return {
    type: GET_COMPANY_INFORMATION,
    payload: payload
  };
};

export const getQuote = payload => {
  return {
    type: GET_QUOTE,
    payload: payload
  };
};

export const getPlanInfo = payload => {
  return {
    type: GET_PLAN_INFO,
    payload: payload
  };
};

export const getPlanSelected = payload => {
  return {
    type: GET_PLAN_SELECTED,
    payload: payload
  };
};

export const getTramoAsegurado = payload => {
  return {
    type: GET_TRAMO_ASEGURADO,
    payload: payload
  };
};

export const getPreCotizacion = payload => {
  return {
    type: GET_PRECOTIZACION,
    payload: payload
  };
};

export const getIdPrecotizacion = payload => {
  return {
    type: GET_ID_PRECOTIZACION,
    payload: payload
  };
};

export const getSaludDigital = payload => {
  return {
    type: GET_SALUD_DIGITAL,
    payload: payload
  };
};

export const getRegiones = payload => {
  return {
    type: GET_REGIONES,
    payload: payload
  };
};

export const nextPage = () => {
  return {
    type: NEXT_PAGE
  };
};

export const prevPage = () => {
  return {
    type: PREV_PAGE
  };
};

export const reset = () => {
  return {
    type: RESET
  };
};

export const openModal = () => {
  return {
    type: OPEN_MODAL
  };
};

export const getPersonalId = payload => {
  return {
    type: GET_PERSONAL_ID,
    payload: payload
  };
};

export const getCompanyId = payload => {
  return {
    type: GET_COMPANY_ID,
    payload: payload
  };
};

export const obtenerPersona = payload => {
  return {
    type: OBTENER_PERSONA,
    payload: payload
  };
};

export const obtenerEmpresa = payload => {
  return {
    type: OBTENER_EMPRESA,
    payload: payload
  };
};

export const obtenerPrecotizacion = payload => {
  return {
    type: OBTENER_PRECOTIZACION,
    payload: payload
  };
};

export const guardarTrabajadoresPorCotizacion = payload => {
  return {
    type: OBTENER_TRABAJADORES,
    payload: payload
  };
};

export const guardarPatrocinadorPorCotizacion = payload => {
  return {
    type: OBTENER_PATROCINADOR,
    payload: payload
  };
};

export const guardarTarjetasPorCotizacion = payload => {
  return {
    type: OBTENER_TARJETAS,
    payload: payload
  };
};


export const guardarCoberturasPorCotizacion = payload => {
  return {
    type: OBTENER_COBERTURAS,
    payload: payload
  };
};

export const guardarDistribucionTramosPorCotizacion = payload => {
  return {
    type: OBTENER_DISTRIBUCION_TRAMOS,
    payload: payload
  };
};

export const guardarPoblacionPorCotizacion = payload => {
  return {
    type: OBTENER_POBLACION,
    payload: payload
  };
};

export const guardarCombinacionPlanesPorCotizacion = payload => {
  return {
    type: OBTENER_COMBINACION_PLANES,
    payload: payload
  };
};

export const guardarUfPorFechaActual = payload => {
  return {
    type: OBTENER_UF,
    payload: payload
  };
};

export const guardarHistorialCotizacion = payload => {
  return {
    type: OBTENER_HISTORIAL_COTIZACIONES,
    payload: payload
  };
};

export const guardarEjecutivos = payload => {
  return {
    type: OBTENER_EJECUTIVO,
    payload: payload
  };
};