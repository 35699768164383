import React from 'react'
import { Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CoberturaIcono } from './CoberturaIcono';

const CasillaSeleccion = ({coberturaId, objCobertura, idTarjeta, blnSimulador, blnDesabilidada, seleccionarCobertura}) => {
  return (
    <Form.Check 
      type="checkbox" 
      className="tarjeta-cobertura-check" 
      id={`check-${coberturaId}`} 
      label=""
      checked={objCobertura.blnSeleccionada}
      onClick={() => {seleccionarCobertura(idTarjeta, objCobertura.grupo, blnSimulador)}}
      disabled={blnDesabilidada}
      custom
      />
  )
}


export const CoberturaCasillaSeleccion = (objProps) => {

  let { idTarjeta, strClassName, objCobertura, blnSimulador, coberturaId, seleccionarCobertura, blnDesabilidada } = objProps;
  let strClassNameTope = objCobertura.blnCheckboxMostrar ? "": "mr-check";
  return (
    <>
      <div id={coberturaId} className={ strClassName }>
        <CoberturaIcono grupo={objCobertura.grupo} />
        <p className='tarjeta-cobertura-nombre'>
          <span className='tarjeta-cobertura-tipo'>{objCobertura.cobertura.tipo}</span> 
          {objCobertura.cobertura.nombre}
        </p>

        <p className={`tarjeta-cobertura-tope ${strClassNameTope}`}>{objCobertura.cobertura.tope}</p>
        { objCobertura.blnCheckboxMostrar && (

          objCobertura.independiente ? (
            <CasillaSeleccion 
              coberturaId={coberturaId}
              objCobertura={objCobertura}
              idTarjeta={idTarjeta}
              blnSimulador={blnSimulador}
              blnDesabilidada={blnDesabilidada}
              seleccionarCobertura={seleccionarCobertura}
            />
          ) : (
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">{objCobertura.mensajeDependiente}</Tooltip>}>
              <span className='tarjeta-cobertura--tooltip'>
                <CasillaSeleccion 
                  coberturaId={coberturaId}
                  objCobertura={objCobertura}
                  idTarjeta={idTarjeta}
                  blnSimulador={blnSimulador}
                  blnDesabilidada={blnDesabilidada}
                  seleccionarCobertura={seleccionarCobertura}
                />
              </span>
            </OverlayTrigger>
          )
        )}
      </div>
    </>

  )
}
