import React from 'react'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CoberturaIcono } from './CoberturaIcono';

const Lista = ({coberturaId, strClassName, blnDesabilidada, objCobertura, objGrupo, blnSimulador, objTarjeta, seleccionarCoberturaLista}) => {
  return (
    <Dropdown >
      <Dropdown.Toggle id={coberturaId} className={ strClassName } disabled={blnDesabilidada} >
        <CoberturaIcono grupo={objCobertura.grupo} />
        <p className='tarjeta-cobertura-nombre'>
          <span className='tarjeta-cobertura-tipo'>{objCobertura.cobertura.tipo}</span> 
          {objCobertura.cobertura.nombre}
        </p>

        <p className='tarjeta-cobertura-tope'>{objCobertura.cobertura.tope}</p>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {objGrupo.coberturas.map((objOpcion, intIndice) => (
          <Dropdown.Item 
            id={`${objTarjeta.id}-${objOpcion.id}`} 
            key={`${intIndice}-${objOpcion.id}`} 
            as="button" 
            onClick={() => {seleccionarCoberturaLista(objTarjeta.id, objOpcion, objGrupo.grupo, blnSimulador)}} >
              {objOpcion.nombre}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}


export const CoberturaLista = (objProps) => {

  let {objTarjeta, objCobertura, coberturaId, strClassName, blnSimulador, seleccionarCoberturaLista, blnDesabilidada } = objProps;
  let objGrupo = objTarjeta.lstCobertura.find((objCoberturaAux) => objCoberturaAux.grupo === objCobertura.grupo);

  return (
    <>
      {
        objCobertura.independiente ? (
          <Lista 
            coberturaId={coberturaId} 
            strClassName={strClassName}
            blnDesabilidada={blnDesabilidada} 
            objCobertura={objCobertura}
            objGrupo={objGrupo}
            blnSimulador={blnSimulador}
            objTarjeta={objTarjeta}
            seleccionarCoberturaLista={seleccionarCoberturaLista}
          />
        ):(
          <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">{objCobertura.mensajeDependiente}</Tooltip>}>
            <span>
              <Lista 
                coberturaId={coberturaId} 
                strClassName={strClassName}
                blnDesabilidada={blnDesabilidada} 
                objCobertura={objCobertura}
                objGrupo={objGrupo}
                blnSimulador={blnSimulador}
                objTarjeta={objTarjeta}
                seleccionarCoberturaLista={seleccionarCoberturaLista}
              />
            </span>
          </OverlayTrigger>
        )
      }
    </>
  )
}
